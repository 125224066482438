import React, { useState, useEffect } from 'react';
import './Toast.scss';

let toastTimeout;

const Toast = ({ message, type, onClose }) => {
  useEffect(() => {
    if (toastTimeout) {
      clearTimeout(toastTimeout);
    }
    toastTimeout = setTimeout(() => {
      onClose();
    }, 3000);

    return () => {
      if (toastTimeout) {
        clearTimeout(toastTimeout);
      }
    };
  }, [message, onClose]);

  return (
    <div className={`toast ${type}`}>
      {message}
    </div>
  );
};

export const useToast = () => {
  const [toast, setToast] = useState({ visible: false, message: '', type: '' });

  const showToast = (message, type = 'info') => {
    setToast({ visible: true, message, type });
  };

  const hideToast = () => {
    setToast({ visible: false, message: '', type: '' });
  };

  const ToastComponent = toast.visible ? (
    <Toast message={toast.message} type={toast.type} onClose={hideToast} />
  ) : null;

  return {
    ToastComponent,
    showToast,
    hideToast
  };
};

export default Toast;
