import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Premium.scss';

// 导入图片资源
import vipIcon from '../img/vipIcon.svg';
import vipName from '../img/vipName.svg';
import vipBg from '../img/vipBg.svg';
import aboutBg from '../img/aboutBg.svg';
import backIcon from '../img/back.svg';

const Premium = () => {
    const navigate = useNavigate();
    const handleBtnClick = () => {
        navigate('/pay');
    };

    return (
        <div className="vip">
            <div className="gradient-bg" />
            
            <div className="navbar">
                <div className="back" onClick={() => navigate(-1)}>
                    <img src={backIcon} alt="返回" />
                </div>
                <div className="title">会员中心</div>
            </div>

            {/* VIP信息卡片 */}
            <div className="vipMax">
                <div className="vipBox">
                    <div className="left">
                        <div className="vipIconBox">
                            <div className="Icon">
                                <img src={vipIcon} alt="VIP" width="30" height="30" />
                            </div>
                        </div>
                        <div className="text">
                            <div className="name">VIP会员</div>
                            <div className="num">有效期至：2025-03-25</div>
                        </div>
                    </div>
                </div>
                <div className="vipBg">
                    <img src={vipBg} alt="vip background" />
                </div>
            </div>

            {/* 主要内容区域 */}
            
            <div className="mainBox">
                <div className="title">
                    <img src={vipName} alt="VIP特权" />
                </div>

                <div className="cardBox">
                    {/* 特权卡片1 */}
                    <div className="card card1">
                        <div className="name">1.文本对话畅聊，无次数限制</div>
                        <div className="desc">与喜欢的角色聊天不断线，畅想海量即时消息</div>
                    </div>

                    {/* 特权卡片2 */}
                    <div className="card card2">
                        <div className="name">2.沉浸私密聊天</div>
                        <div className="desc">与喜欢的角色开启更加私密的聊天模式，荷尔蒙分泌不停</div>
                    </div>

                    {/* 特权卡片3 */}
                    <div className="card card3">
                        <div className="name">3.梦幻剧场生成速度加速</div>
                        <div className="desc">生动记录和喜欢的角色跨次元语聊恋爱</div>
                    </div>
                </div>

                {/* 购买按钮 */}
                <div className="btn" onClick={handleBtnClick}>
                    <div className="price">¥ 9.9/月</div>
                    <div className="unit">立即开通</div>
                </div>
            </div>
        </div>
    );
};

export default Premium;
