import React, { createContext, useContext, useState } from 'react';

const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
  const [playingMessageId, setPlayingMessageId] = useState(null);
  const [audio, setAudio] = useState(null);

  const playAudio = (messageId, audioUrl) => {
    // 如果有正在播放的音频，先停止
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }

    // 如果点击的是当前正在播放的消息，则停止播放
    if (messageId === playingMessageId) {
      setPlayingMessageId(null);
      setAudio(null);
      return;
    }

    // 播放新的音频
    const newAudio = new Audio(audioUrl);
    newAudio.play();
    newAudio.onended = () => {
      setPlayingMessageId(null);
      setAudio(null);
    };

    setPlayingMessageId(messageId);
    setAudio(newAudio);
  };

  const stopAudio = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
    setPlayingMessageId(null);
    setAudio(null);
  };

  return (
    <AudioContext.Provider value={{ playingMessageId, playAudio, stopAudio }}>
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error('useAudio must be used within an AudioProvider');
  }
  return context;
};
