import Login from "./pages/Login";
import Register from "./pages/Register";
import Browse from "./pages/Browse";
import Chat from "./pages/Chat";
import ChatRoom from "./pages/ChatRoom";
import Circum from "./pages/Circum";
import Event from "./pages/Event";
import My from "./pages/My";
import EditUser from "./pages/EditUser";
import Premium from "./pages/Premium";
import Pay from "./pages/Pay";
import AvaDetail from "./pages/AvaDetail";
import Feedback from "./pages/Feedback";
import SearchAva from "./pages/SearchAva";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useAuth } from "./context/AuthContext";
import PlusPage from "./components/PlusPage"
import { AnimatePresence, motion } from 'framer-motion';
import { AudioProvider } from './context/AudioContext';

import "./style.scss";

function App() {
  const variants = {
    hidden: { x: '100%', opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };
  const transition = {
    duration: 0.4,
    ease: 'easeInOut',
  };

  const { currentUser } = useAuth();
  console.log(currentUser);
  // const location = useLocation();

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/login" />;
    }
    return children
  };

  const RootRoute = () => {
    if (currentUser) {
      return <Navigate to="/browse" />;
    }
    return <Navigate to="/login" />;
  };

  useEffect(() => {
    console.log('app');
  })

  const MemoBrowser = useMemo(() => Browse, [currentUser]);
  const MemoChat = useMemo(() => Chat, [currentUser]);
  const MemoMy = useMemo(() => My, [currentUser]);

  return (
    <AudioProvider>
      <BrowserRouter>
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/">
              <Route index element={<RootRoute />} />
              <Route path="login" element={<Login/>}/>
              <Route path="register" element={<Register/>}/>
              <Route path="browse" element={
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={transition}
                  className="page"
                >
                  <ProtectedRoute><MemoBrowser /></ProtectedRoute>
                </motion.div>
              }/>
              <Route path="chat" element={
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={transition}
                  className="page"
                >
                  <ProtectedRoute><MemoChat /></ProtectedRoute>
                </motion.div>
              }/>
              <Route path="chatroom" element={
                <motion.div
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={{
                    initial: { opacity: 0.5 },
                    animate: { opacity: 1 },
                    exit: { opacity: 1 }
                  }}
                  transition={{ duration: 0.4 }}
                  className="page"
                >
                  <ProtectedRoute><ChatRoom /></ProtectedRoute>
                </motion.div>
              } />
              <Route path="feedback" element={
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={transition}
                  className="page"
                >
                  <ProtectedRoute><Feedback /></ProtectedRoute>
                </motion.div>
              } />
              <Route path="plus/" element={
                // <motion.div
                //     initial="hidden"
                //     animate="visible"
                //     exit="hidden"
                //     variants={variants}
                //     transition={transition}
                //     // className="page"
                // >
                  <PlusPage />
                // </motion.div>
              } />
              <Route path="circum" element={
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={transition}
                  className="page"
                >
                  <ProtectedRoute><Circum /></ProtectedRoute>
                </motion.div>
              } />
              <Route path="event" element={
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={transition}
                  className="page"
                >
                  <ProtectedRoute><Event /></ProtectedRoute>
                </motion.div>
              } />
              <Route path="my" element={
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={transition}
                  className="page"
                >
                  <ProtectedRoute><MemoMy /></ProtectedRoute>
                </motion.div>
              } />
              <Route path="my/edit" element={
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={transition}
                  className="page"
                >
                  <ProtectedRoute><EditUser /></ProtectedRoute>
                </motion.div>
              } />
              <Route path="premium" element={
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={transition}
                  className="page"
                >
                  <ProtectedRoute><Premium /></ProtectedRoute>
                </motion.div>
              } />
              <Route path="pay" element={
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={transition}
                  className="page"
                >
                  <ProtectedRoute><Pay /></ProtectedRoute>
                </motion.div>
              } />
              <Route path="avadetail" element={
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={transition}
                  className="page"
                >
                  <ProtectedRoute><AvaDetail /></ProtectedRoute>
                </motion.div>
              } />
              <Route path="searchava" element={
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={transition}
                  className="page"
                >
                  <ProtectedRoute><SearchAva /></ProtectedRoute>
                </motion.div>
              } />
            </Route>
          </Routes>
        </AnimatePresence>
      </BrowserRouter>
    </AudioProvider>
  );
}

export default App;
