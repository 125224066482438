import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../context/AxiosContext';
import BottomNav from '../components/BottomNav';
import './My.scss';
import moneyIcon from '../img/money.svg';
import vipIcon from '../img/vipIcon.svg';
import feedbackIcon from '../img/feedback.svg';
import slidingBlockIcon from '../img/sliding_block.svg';
import Masonry from 'react-masonry-css';
import vipBg from '../img/vipBg.svg';
import moneyBag from '../img/moneyBag.svg';
import { ReactComponent as EditIcon } from '../img/icon-edit.svg';
import { styled, alpha } from '@mui/material/styles';
import { downloadFileByUrl } from '../utils/index.ts';
import { debounce } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color: '#fff',
      boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      background: 'linear-gradient(180deg, rgb(27 9 31) 0%, rgba(120, 70, 153, 0.4) 100%)',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[300],
      }),
    },
  }));
export const DEFAULT_URL = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

const My = () => {
    const navigate = useNavigate();
    const [breakpointCols, setBreakpointCols] = useState({
        default: 1,
        1100: 1,
        700: 1
    });
    const [userInfo, setUserInfo] = useState({});
    const [theaterList, setTheaterList] = useState([]);
    const [showInput, setShowInput] = useState(false);
    const [editTitle, setEditTitle] = useState('');
    const [currentEditId, setCurrentEditId] = useState(null);
    const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
    const [userCredit, setUserCredit] = useState('-');
    const [vip, setVip] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreTheaters, setHasMoreTheaters] = useState(true);
    const [isLoadingTheaters, setIsLoadingTheaters] = useState(false);
    const PAGE_SIZE = 3;
    const videoRefs = useRef([]);
    const editTitleRef = useRef(null);
    const loadMoreRef = useRef(null);
    const defaultDescriptions = [
        '啊啊啊啊尖叫',
        '姐妹们谁懂啊！',
        '被AI男友治愈社恐的第n天',
        '他教我如何对crush说……',
        '你能接受AI恋人吗？',
    ];
    const contentTag = "\n#AVA  #AVAWorld   #AW推荐官   #AI情感陪伴";
    const [shareAnchorEl, setShareAnchorEl] = useState(null);
    const openShare = Boolean(shareAnchorEl);

    const handleShareClick = useCallback((event) => {
        setShareAnchorEl(event.currentTarget);
    }, []);

    const handleShareClose = useCallback(() => {
        setShareAnchorEl(null);
    }, []);

    const handleShareCommand = useCallback(async (command) => {
        const item = theaterList.find(v => v.theater_id === currentEditId);
        if (command === 'download') {
            downloadFileByUrl(item.video_url);
        } else if (command === 'community') {
            await axiosInstance.get('/share_theater_to_community', {
                params: { theater_id: item.theater_id }
            });
        } else if (command === 'xiaohongshu') {
            try {
                const response = await axiosInstance.get('/get_rednote_share_params', {
                    params: { theater_id: item.theater_id }
                });
                const shareParams = response?.data?.result;
                
                if (shareParams && window.xhs) {
                    const content = defaultDescriptions[Math.floor(Math.random() * defaultDescriptions.length)];
                    window.xhs.share({
                        shareInfo: {
                            type: item.video_url ? 'video' : 'normal',
                            title: item.title || content,
                            content: content+contentTag,
                            images: item.image_url ? [item.image_url] : [],
                            video: item.video_url || '',
                            cover: item.cover_url || item.image_url || ''
                        },
                        verifyConfig: {
                            appKey: shareParams.appKey,
                            nonce: shareParams.nonce,
                            timestamp: shareParams.timestamp,
                            signature: shareParams.signature
                        },
                        fail: (e) => {
                            console.error('分享到小红书失败:', e);
                        }
                    });
                } else {
                    console.error('分享参数获取失败或小红书SDK未加载');
                }
            } catch (error) {
                console.error('小红书分享出错:', error);
            }
        }
        handleShareClose();
    }, [currentEditId, theaterList, handleShareClose]);

    useEffect(() => {
        getUserInfo();
        getVipTime();
        getTheaterList();
        getUserCredit();
    }, []);

    const getUserInfo = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/get_user');
            if (response.data.result) {
                setUserInfo(response.data.result);
                localStorage.setItem('userInfo', JSON.stringify(response.data.result));
            }
        } catch (error) {
            console.error('Get user info error:', error);
        }
    }, []);

    const getVipTime = useCallback(async () => {
        try {
            const { data } = await axiosInstance.get('/get_subscribe_expired');
            setVip(data?.result);
        } catch (error) {
            console.error('Get VIP time error:', error);
        }
    }, []);

    const getUserCredit = useCallback(async () => {
        try {
            const res = await axiosInstance.get('/get_user_credit');
            if (res.data.message === 'success') {
                setUserCredit(res.data.result || 0);
            }
        } catch (error) {
            console.error('Get user credit error:', error);
        }
    }, []);

    const getTheaterList = useCallback(async (page = 1) => {
        // Don't load if already loading
        if (isLoadingTheaters) {
            return;
        }
        
        // Don't load next page if we've reached the end
        if (page > 1 && !hasMoreTheaters) {
            return;
        }
        
        console.log(`Loading page ${page}`);
        
        setIsLoadingTheaters(true);
        
        try {
            const response = await axiosInstance.get('/get_user_theater_list', {
                params: {
                    page,
                    page_size: PAGE_SIZE
                }
            });
            
            if (response.data.results) {
                const newTheaters = response.data.results;
                
                if (page === 1) {
                    // Reset list for first page
                    setTheaterList(newTheaters);
                    videoRefs.current = [];
                    setCurrentPage(1);
                } else {
                    // Append new items to the list for pagination
                    setTheaterList(prevList => [...prevList, ...newTheaters]);
                }
                
                // Update pagination state
                setCurrentPage(page);
                
                // Check if we've reached the end
                setHasMoreTheaters(newTheaters.length === PAGE_SIZE);
                
                console.log(`Updated to page ${page}, has more: ${newTheaters.length === PAGE_SIZE}`);
            }
        } catch (error) {
            console.error('Get theater list error:', error);
        } finally {
            setIsLoadingTheaters(false);
        }
    }, [isLoadingTheaters, hasMoreTheaters]);

    const loadMoreTheaters = useCallback(() => {
        if (hasMoreTheaters && !isLoadingTheaters) {
            getTheaterList(currentPage + 1);
        }
    }, [currentPage, getTheaterList, hasMoreTheaters, isLoadingTheaters]);

    const handleVideoClick = useCallback((index) => {
        const videoElements = videoRefs.current.filter(ref => ref !== null);
        const videoElement = videoElements[index];
        
        if (!videoElement) return;

        if (currentPlayingIndex === index) {
            if (videoElement.paused) {
                videoElement.play();
            } else {
                videoElement.pause();
                videoElement.muted = true;
                setCurrentPlayingIndex(null);
            }
            return;
        }

        if (currentPlayingIndex !== null) {
            const currentVideoElements = videoRefs.current.filter(ref => ref !== null);
            const currentVideo = currentVideoElements[currentPlayingIndex];
            
            if (currentVideo) {
                currentVideo.pause();
                currentVideo.muted = true;
                currentVideo.currentTime = 0;
            }
        }

        if (videoElement) {
            videoElement.muted = false;
            videoElement.currentTime = 0;
            videoElement.play();
            setCurrentPlayingIndex(index);

            videoElement.onended = () => {
                videoElement.pause();
                videoElement.muted = true;
                setCurrentPlayingIndex(null);
            };
        }
    }, [currentPlayingIndex]);

    const handleShare = useCallback((evt, item) => {
        setCurrentEditId(item.theater_id);
        handleShareClick(evt);
    }, [handleShareClick]);

    const handleSaveTitle = useCallback(async () => {
        if (!editTitle || !currentEditId) {
            setShowInput(false);
            return;
        }

        try {
            await axiosInstance.post('/modify_theater', {
                theater_id: currentEditId,
                title: editTitle
            });
            setShowInput(false);
            setEditTitle('');
            setCurrentEditId(null);
            getTheaterList(1); 
        } catch (error) {
            console.error('Save title error:', error);
        }
    }, [editTitle, currentEditId, getTheaterList]);

    const handleLogout = useCallback(() => {
        localStorage.clear();
        navigate('/login');
    }, [navigate]);

    const handleEditTitle = useCallback((item) => {
        setShowInput(true);
        setCurrentEditId(item.theater_id);
        setEditTitle(item.title || '');
    }, []);

    useEffect(() => {
        if (showInput) {
            editTitleRef.current?.focus();
        }
    }, [showInput]);

    useEffect(() => {
        const updateLayout = debounce(() => {
            const aspectRatio = window.innerWidth / window.innerHeight;

            if (aspectRatio < 0.8) {
                setBreakpointCols({
                    default: 1,
                    1100: 1,
                    700: 1
                });
            } else if (aspectRatio < 1.3) {
                setBreakpointCols({
                    default: 2,
                    1100: 2,
                    700: 1
                });
            } else {
                setBreakpointCols({
                    default: 3,
                    1100: 2,
                    700: 1
                });
            }
        }, 500);
        updateLayout();
        window.addEventListener('resize', updateLayout);
        return () => window.removeEventListener('resize', updateLayout);
    }, []);

    useEffect(() => {
        if (!loadMoreRef.current) return;
        
        console.log('Setting up observer for loading more');
        
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && hasMoreTheaters && !isLoadingTheaters) {
                    console.log(`加载指示器可见，加载下一页`);
                    loadMoreTheaters();
                }
            },
            { 
                threshold: 0.1,
                rootMargin: '200px 0px 0px 0px'
            }
        );
        
        observer.observe(loadMoreRef.current);
        
        return () => {
            observer.disconnect();
        };
    }, [loadMoreTheaters, hasMoreTheaters, isLoadingTheaters]);

    return (
        <div className="my">
            <div className="gradient-bg"></div>
            <div className="content">
                <div className="topbu">
                    <div>关于我</div>
                    <div className="yiian" onClick={handleLogout}>退出登录</div>
                </div>
                
                <div className="userInfo">
                    <div className="leftBox">
                        <div className="avator">
                            <img src={userInfo.avatar_url ?? DEFAULT_URL} alt="avatar" />
                        </div>
                        <div className="InfoBox">
                            <div className="name">{userInfo.user_name}</div>
                            <div className="edit" onClick={() => navigate('/my/edit')}>
                                编辑 <i className="edit-btn icon-btn">
                                        <EditIcon />
                                    </i>
                            </div>
                        </div>
                    </div>
                    <div className="rightBox" onClick={() => navigate('/feedback')}>
                        <img src={feedbackIcon} alt="feedback" width="20" height="20" />
                    </div>
                </div>

                <div className="vipMax">
                    <div className="vipBox">
                        <div className="left">
                            { vip ? <div className="vipIconBox">
                                <div className="Icon">
                                    <img src={vipIcon} alt="VIP" width="30" height="30" />
                                </div>
                            </div> : null}
                            {vip ? <div className="text">
                                <div className="name">VIP会员</div>
                                <div className="num">有效期至：{vip}</div>
                            </div> : <div className="text">
                                <div className="name">开通会员</div>
                                <div className="num">和喜欢的角色解锁更多玩法</div>
                            </div>}
                        </div>
                        <div className="right">
                            <div className="lijixufei" onClick={() => navigate('/premium')}>{vip ? '立即续费' : '点此订阅'}</div>
                        </div>
                    </div>
                    <div className="vipBg">
                        <img src={vipBg} alt="vip background" />
                    </div>
                </div>

                <div className="aboutBg">
                    <div className="mainBox">
                        <div className="money">
                            <div className="moneyBg">
                                <img src={moneyBag} alt="money background" />
                            </div>
                            <div className="money1">
                                <div className="title">
                                    <img src={moneyIcon} alt="money" />
                                    <span style={{ marginLeft: '30px' }}>我的钱包</span>
                                </div>
                                <div>{userCredit}</div>
                            </div>
                            <div className="desc">
                                <p>每轮对话消耗 <span>2</span> 额度，剧场生成花费 <span>10</span> 额度。</p>
                                <p>首次注册送 <span>50</span> 额度，非会员登录每日送 <span>30</span> 额度。</p>
                                <p>会员暂无额度限制。</p>
                            </div>

                        </div>

                        <div className="theater">
                            <Masonry
                                breakpointCols={breakpointCols}
                                className="masonry-grid"
                                columnClassName="masonry-grid_column"
                            >
                                {/* 页面标题 */}
                                <div className="title" style={{ gridColumn: '1 / -1', width: '100%' }}>
                                    <div>我的发布</div>
                                    <div className="sliding-block-wrapper">
                                        <img src={slidingBlockIcon} alt="sliding block" className="sliding-block" />
                                    </div>
                                </div>
                                
                                {(theaterList || []).map((item, index) => {
                                    return (
                                        <div 
                                            className="itemBox" 
                                            key={item.theater_id || index}
                                        >
                                            {showInput && currentEditId === item.theater_id ? (
                                                <div className="title">
                                                    <div className="input-box">
                                                        <input
                                                            ref={editTitleRef}
                                                            type="text"
                                                            value={editTitle}
                                                            onChange={(e) => setEditTitle(e.target.value)}
                                                            placeholder="请编辑视频标题"
                                                        />
                                                        <button onClick={handleSaveTitle}>保 存</button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="title">
                                                    <div style={{ display: 'flex', alignItems: 'center' }} className='title-label'>
                                                        <span>{item.title || ''}</span>
                                                        <i 
                                                            className="edit-btn icon-btn" 
                                                            onClick={e => handleEditTitle(item)}>
                                                            <EditIcon />
                                                        </i>
                                                    </div>
                                                    <div className="fenxiang" onClick={(evt) => handleShare(evt, item)}>分享</div>
                                                </div>
                                            )}
                                            <div className="time">
                                                <div>创作者: <span>@{userInfo.user_name}</span></div>
                                                <div>{item.add_time || ''}</div>
                                            </div>
                                            <div className="bgc">
                                                <video
                                                    ref={el => {
                                                        if (el) {
                                                            videoRefs.current[index] = el;
                                                        }
                                                    }}
                                                    src={item.video_url}
                                                    muted
                                                    controls={false}
                                                    autoPlay={false}
                                                    onClick={() => handleVideoClick(index)}
                                                    playsInline
                                                    preload="metadata"
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                                
                                {/* 加载下一页的触发元素 */}
                                {hasMoreTheaters && (
                                    <div ref={loadMoreRef} style={{ width: '100%', height: '20px' }} />
                                )}
                                
                                {/* 加载状态指示器 */}
                                {isLoadingTheaters && (
                                    <div style={{ textAlign: 'center', padding: '10px', width: '100%' }}>
                                        <CircularProgress size={20} /> 加载更多视频...
                                    </div>
                                )}
                                
                                {/* 到底了的提示 */}
                                {!hasMoreTheaters && theaterList.length > 0 && (
                                    <div style={{ textAlign: 'center', padding: '10px', width: '100%' }}>
                                        没有更多视频了
                                    </div>
                                )}
                            </Masonry>
                        </div>
                    </div>
                </div>
            </div>
            {showInput && (
                <div className="input-overlay">
                    <div className="input-container">
                        <input
                            type="text"
                            value={editTitle}
                            onChange={(e) => setEditTitle(e.target.value)}
                            placeholder="请输入标题"
                        />
                        <div className="button-container">
                            <button onClick={() => setShowInput(false)}>取消</button>
                            <button onClick={handleSaveTitle}>确定</button>
                        </div>
                    </div>
                </div>
            )}
            <BottomNav />
            <StyledMenu
                id="share-menu"
                anchorEl={shareAnchorEl}
                open={openShare}
                onClose={handleShareClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={evt => handleShareCommand('community')}>分享到角色社区</MenuItem>
                <MenuItem onClick={evt => handleShareCommand('xiaohongshu')}>分享到小红书</MenuItem>
                <MenuItem onClick={evt => handleShareCommand('download')}>下载</MenuItem>
            </StyledMenu>
        </div>
    );
};

export default My;