import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import backIcon from '../img/back.svg';
import './EditUser.scss';
import axiosInstance from "../context/AxiosContext";

const EditUser = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        user_nickname: '',
        user_whoami: '',
        avatar_url: '',
        user_name: ''
    });

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get('/get_user');
                console.log(response);
                if (response.data?.result) {
                    const userInfo = response.data.result;
                    setForm({
                        user_nickname: userInfo.user_nickname || '',
                        user_whoami: userInfo.user_whoami || '',
                        avatar_url: userInfo.avatar_url,
                        user_name: userInfo.user_name || ''
                    });
                }
            } catch (error) {
                console.error('获取用户信息失败:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserInfo();
    }, []);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setForm(prev => ({
                    ...prev,
                    avatar_url: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const cropImageToSquare = (imageUrl) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const size = Math.min(img.width, img.height);
                canvas.width = size;
                canvas.height = size;
                const ctx = canvas.getContext('2d');
                
                // 计算裁剪位置，使图片居中
                const offsetX = (img.width - size) / 2;
                const offsetY = (img.height - size) / 2;
                
                ctx.drawImage(img, offsetX, offsetY, size, size, 0, 0, size, size);
                resolve(canvas.toDataURL('image/jpeg', 0.8));
            };
            img.src = imageUrl;
        });
    };

    const handleSubmit = async () => {
        // 表单验证
        if (!form.user_name || !form.user_nickname || !form.user_whoami) {
            alert('请填写所有必填字段');
            return;
        }

        let params = { ...form };

        // 处理头像
        if (params.avatar_url && !params.avatar_url.startsWith('http')) {
            // 裁剪图片为正方形
            const croppedImage = await cropImageToSquare(params.avatar_url);
            params.user_avatar_b64 = croppedImage
                .replace(/^data:image\/jpeg;base64,/, '');
            delete params.avatar_url;
        }

        try {
            await axiosInstance.post('/modify_user', params);
            // 更新本地存储的用户信息
            const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
            localStorage.setItem('userInfo', JSON.stringify({
                ...userInfo,
                user_name: form.user_name,
                user_nickname: form.user_nickname,
                user_whoami: form.user_whoami,
                avatar_url: form.avatar_url.startsWith('http') ? form.avatar_url : userInfo.avatar_url
            }));
            navigate("/my");
        } catch (error) {
            console.error('修改用户信息失败:', error);
            alert('修改用户信息失败，请重试');
        }
    };

    return (
        <div className="editUser">
            <div className="navbar">
                <div className="back" onClick={() => navigate("/my")}>
                    <img src={backIcon} alt="返回" />
                </div>
                <div className="title">对话设定</div>
                <div className="placeholder"></div>
            </div>

            <div className="photo" onClick={() => document.getElementById('fileInput').click()}>
                <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                />
                <img 
                    src={form.avatar_url} 
                    alt="avatar"
                    style={{ opacity: loading ? 0.5 : 1 }}
                />
            </div>
            <div className="userName">上传头像</div>

            <div className="formBox">
                <div className="formItem">
                    <label>用户名</label>
                    <input
                        type="text"
                        className="custom-input"
                        autoComplete="off"
                        value={form.user_name}
                        onChange={(e) => setForm(prev => ({ ...prev, user_name: e.target.value }))}
                        placeholder="请填写用户名"
                        disabled={loading}
                    />
                </div>
                <div className="formItem">
                    <label>用户昵称</label>
                    <input
                        type="text"
                        className="custom-input"
                        autoComplete="off"
                        value={form.user_nickname}
                        onChange={(e) => setForm(prev => ({ ...prev, user_nickname: e.target.value }))}
                        placeholder="请填写用户昵称"
                        disabled={loading}
                    />
                </div>
                <div className="formItem">
                    <label>我是谁</label>
                    <input
                        type="text"
                        className="custom-input"
                        autoComplete="off"
                        value={form.user_whoami}
                        onChange={(e) => setForm(prev => ({ ...prev, user_whoami: e.target.value }))}
                        placeholder="请输入我是谁"
                        disabled={loading}
                    />
                </div>
            </div>

            <button 
                className="gradient-button"
                onClick={handleSubmit}
                disabled={loading}
            >
                {loading ? '加载中...' : '保存'}
            </button>
        </div>
    );
};

export default EditUser;
