import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: '/api',
    timeout: 10000
});

// 请求拦截器
axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

// 响应拦截器：处理401错误
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            // 清除token
            localStorage.removeItem('access_token');
            // 重定向到登录页
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export const WssUrl = `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://avaworld.com.cn`

export default axiosInstance;
