import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {QRCodeCanvas} from "qrcode.react";
import './Pay.scss';
import axiosInstance from '../context/AxiosContext';

// 导入图片资源
import backIcon from '../img/back.svg';
import weiIcon from '../img/icon-weixin.svg';
import alipayIcon from '../img/icon-alipay.svg';
import rightIcon from '../img/icon-right.svg';
import successIcon from '../img/icon-success.svg';
import failIcon from '../img/icon-fail.svg';

const Pay = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectType, setSelectType] = useState('wechat');
    const [payResult, setPayResult] = useState('');
    const qrCodeRef = useRef(null);
    const [amount, setAmount] = useState(9.9); // 默认金额
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [orderId, setOrderId] = useState('');
    const [remainingTime, setRemainingTime] = useState(180); // 3分钟倒计时
    const [pollingIntervalId, setPollingIntervalId] = useState(null);
    const [countdownIntervalId, setCountdownIntervalId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // 检查是否从其他页面传入了金额
        if (location.state?.amount) {
            setAmount(location.state.amount);
        }
    }, [location]);

    const onSelectPayChange = useCallback((e) => {
        setSelectType(e.target.value);
    }, []);

    const onPayResultClose = useCallback((e) => {
        if (e === 'ok' || e === 'cancel') {
            // 支付结果关闭后返回上一页
            navigate(-1);
        } else {
            setPayResult('');
        }
    }, [navigate]);
    
    const handleShowPayResult = useCallback((result) => {
        setPayResult(result);
        // 清除所有轮询和倒计时
        if (pollingIntervalId) clearInterval(pollingIntervalId);
        if (countdownIntervalId) clearInterval(countdownIntervalId);
    }, [pollingIntervalId, countdownIntervalId]);

    // 创建支付订单
    const createPaymentOrder = useCallback(async () => {
        try {
            setIsLoading(true);
            // 获取本地存储的用户ID
            const userId = localStorage.getItem('userId') || '';
            
            const response = await axiosInstance.post('/create_payment', {
                amount: amount,
                payment_type: selectType,
                description: `购买AVA会员 - ¥${amount}`,
                user_id: userId
            });
            
            if (response.data.message === 'success') {
                const result = response.data.result;
                setOrderId(result.order_id);
                setQrCodeUrl(result.payment_url);
                startPollingOrderStatus(result.order_id, selectType);
                startCountdown();
                setIsLoading(false);
            } else {
                console.error('创建支付订单失败:', response.data);
                handleShowPayResult('fail');
                setIsLoading(false);
            }
        } catch (error) {
            console.error('创建支付订单出错:', error);
            handleShowPayResult('fail');
            setIsLoading(false);
        }
    }, [amount, selectType, handleShowPayResult]);

    // 开始轮询订单状态
    const startPollingOrderStatus = useCallback((orderId, paymentType) => {
        const intervalId = setInterval(async () => {
            try {
                const response = await axiosInstance.get(`/query_payment/${orderId}?payment_type=${paymentType}`);
                
                if (response.data.message === 'success') {
                    // 假设后端返回状态为 'paid' 表示支付成功
                    if (response.data.result.status === 'paid') {
                        handleShowPayResult('success');
                    }
                }
            } catch (error) {
                console.error('查询订单状态失败:', error);
            }
        }, 3000); // 每3秒查询一次
        
        setPollingIntervalId(intervalId);
    }, [handleShowPayResult]);

    // 开始倒计时
    const startCountdown = useCallback(() => {
        // 设置3分钟倒计时
        setRemainingTime(180);
        
        const intervalId = setInterval(() => {
            setRemainingTime(prev => {
                if (prev <= 1) {
                    clearInterval(intervalId);
                    // 倒计时结束，二维码过期
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
        
        setCountdownIntervalId(intervalId);
    }, []);

    // 将秒数转换为分钟和秒的显示格式
    const formatTime = useCallback((seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return { minutes, seconds: remainingSeconds };
    }, []);

    // 组件挂载时创建订单
    useEffect(() => {
        createPaymentOrder();
        
        // 组件卸载时清理所有定时器
        return () => {
            if (pollingIntervalId) clearInterval(pollingIntervalId);
            if (countdownIntervalId) clearInterval(countdownIntervalId);
        };
    }, [createPaymentOrder, pollingIntervalId, countdownIntervalId]);

    // 长按保存二维码的功能
    let timer;
    const handleQrcodeStart = useCallback((e) => {
        const canvas = qrCodeRef.current;
        if (canvas) {
            const dataURL = canvas.toDataURL('image/png');
            timer = setTimeout(() => {
                // 触发下载逻辑
                const link = document.createElement('a');
                link.href = dataURL;
                link.download = 'qrcode.jpg';
                link.click();
            }, 800); // 长按 0.8 秒触发
        }
    }, []);

    const handleQrcodeEnd = useCallback((e) => {
        clearTimeout(timer);
    }, []);

    // 格式化显示时间
    const timeDisplay = formatTime(remainingTime);

    return (
        <div className="pay-page">
            <div className="gradient-bg" />
            
            <div className="navbar">
                <div className="back" onClick={() => navigate(-1)}>
                    <img src={backIcon} alt="返回" />
                </div>
                <div className="title">支付中心</div>
            </div>
            
            <div className='pay-type-wrap'>
                <div className='pay-money-label'>支付金额</div>
                <div className='pay-money'><span className='pay-money-unit'>￥</span><span className='pay-money-number'>{amount}</span></div>
                <div className='pay-type-select-label'>请选择支付方式</div>
                <div className='pay-type-radio-group'>
                    <label htmlFor="wechat" className={`weixin-pay select-pay-item ${selectType === 'wechat' ? 'checked' : ''}`}>
                        <div className='pay-type-radio'>
                            <img className='right-icon' src={rightIcon} alt=''/>
                            <input type="radio" id='wechat' value="wechat" checked={selectType === 'wechat'} onChange={onSelectPayChange} />
                        </div>
                        <img className='pay-type-img' src={weiIcon} alt="weixin" />
                        <div className='pay-type-label'>微信支付</div>
                    </label>
                    <label htmlFor="alipay" className={`alipay-pay select-pay-item ${selectType === 'alipay' ? 'checked' : ''}`}>
                        <div className='pay-type-radio'>
                            <img className='right-icon' src={rightIcon} alt='' />
                            <input type="radio" id='alipay' value="alipay" checked={selectType === 'alipay'} onChange={onSelectPayChange}/>
                        </div>
                        <img className='pay-type-img' src={alipayIcon} alt="alipay" />
                        <div className='pay-type-label'>支付宝</div>
                    </label>
                </div>
            </div>

            <div className='qcode-view'>
                <div className='qcode-warp'>
                    {isLoading ? (
                        <div className='loading'>加载中...</div>
                    ) : qrCodeUrl ? (
                        <div className='qcode'>
                            <QRCodeCanvas 
                                ref={qrCodeRef} 
                                onTouchStart={handleQrcodeStart} 
                                onTouchEnd={handleQrcodeEnd} 
                                size={200} 
                                marginSize={1} 
                                value={qrCodeUrl} 
                            />
                        </div>
                    ) : (
                        <div className='qcode'>
                            <img width={200} height={200} src={require('../img/qrcode.jpg')} alt="qrcode" />
                        </div>
                    )}
                </div>
                <div className='qcode-tip'>
                    {remainingTime > 0 ? (
                        <p className='tip-text'>请在<span className='number'>{timeDisplay.minutes}</span>分<span className='number'>{timeDisplay.seconds}</span>秒内完成支付，否则二维码将失效</p>
                    ) : (
                        <p className='tip-text'>二维码已失效，请刷新页面重新获取</p>
                    )}
                    <p className='tip-text-1'>扫码二维码，即可进行支付</p>
                    <p className='tip-text-strong'>长按图片可保存支付二维码</p>
                </div>
                {remainingTime === 0 && (
                    <button className='refresh-btn' onClick={createPaymentOrder}>刷新二维码</button>
                )}
            </div>
            {payResult && <PayResult type={payResult} onClose={onPayResultClose}/>}
        </div>
    );
};

const PayResult = ({type, onClose}) => {
    const [icon, setIcon] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    
    useEffect(() => {
        if (type === 'success') {
            setIcon(successIcon);
            setMessage('支付成功');
            
            // 支付成功后3秒自动返回
            const timer = setTimeout(() => {
                navigate(-1);
            }, 3000);
            
            return () => clearTimeout(timer);
        } else if (type === 'fail') {
            setIcon(failIcon);
            setMessage('支付失败');
        } else {
            setIcon('');
        }
    }, [type, navigate]);

    return <div className='pay-result-pop'>
        <div className='pay-result-content'>
            {icon && <img src={icon} alt="" />}
            <div className='pay-result-message'>{message}</div>
            <div className='pay-result-footer'>
                <button className='cancel-btn btn' onClick={e => onClose('cancel')}>返回</button>
                {type === 'fail' && <button className='ok-btn btn' onClick={e => onClose('retry')}>重试</button>}
            </div>
        </div>
    </div>
}

export default Pay;
