import React, { useState } from "react";
import { Link } from "react-router-dom";
import AgreementModal from '../components/AgreementModal';
import axios from 'axios';
import axiosInstance from '../context/AxiosContext';
import loginTopImg from '../img/login-top.svg';
import loginButImg from '../img/login-but.svg';
import "./Login.scss";

const Login = () => {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [isAgreementOpen, setIsAgreementOpen] = useState(false);
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [activeAgreementTab, setActiveAgreementTab] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setErr(false);
    if (!phone || !password) {
      setErr("请输入完整信息");
      setLoading(false);
      return;
    }

    if (!agreed) {
      setErr("请勾选协议");
      setLoading(false);
      return;
    }

    const phoneRegex = /^1[3-9]\d{9}$/;
    if (!phoneRegex.test(phone)) {
      setErr("请输入正确的手机号码");
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("username", phone);
      formData.append("password", password);

      const response = await axios.post('/api/login', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data && response.data.access_token) {
        localStorage.setItem('access_token', response.data.access_token);
        try {
          await axiosInstance.get('/daily_check_in'); // Use axiosInstance
        } catch (checkInError) {
          console.error('Daily check-in failed:', checkInError);
          // 签到失败不影响登录流程
        }
        await new Promise(resolve => setTimeout(resolve, 100));
        setLoading(false);
        setErr(false);
        window.location.href = '/browse';
      } else {
        throw new Error('账号或密码错误');
      }
    } catch (err) {
      console.error('Login error:', err);
      setErr("账号或密码错误");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login">
      <div className="center">
        <div className="imgTop">
          <img src={loginTopImg} alt="Login Top" className="top-img" />
          <img src={loginButImg} alt="Login Button" className="but-img" />
          <div className="title">眼波流转，一秒沉浸入梦</div>
        </div>
        <div className="iptBox">
          <div className="formBox">
            <form onSubmit={handleSubmit}>
              <div className="form-item">
                <label>手机号</label>
                <input
                  key="phone"
                  type="tel"
                  placeholder="请输入手机号"
                  disabled={loading}
                  className="custom-input"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>密码</label>
                <input
                  key="password"
                  type="password"
                  placeholder="请输入密码"
                  disabled={loading}
                  className="custom-input"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
              <div className="btnBox">
                <button 
                  disabled={loading}
                  className="gradient-button"
                >
                  {loading ? "登录中..." : "登录"}
                </button>
              </div>
              <div className="radioBox">
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={agreed}
                    onChange={(e) => setAgreed(e.target.checked)}
                  />
                  <span className="checkmark"></span>
                </label>
                <div className="radioTxt">
                  用户声明已满18岁，并同意
                  <span onClick={() => {setIsAgreementOpen(true); setActiveAgreementTab('agreement');}}>《AVAWorld用户条款》</span>
                  和
                  <span onClick={() => {setIsAgreementOpen(true); setActiveAgreementTab('privacy');}}>《AVAWorld隐私政策》</span>
                  等条款。
                </div>
              </div>
              {err && <div className="error">{err}</div>}
            </form>
          </div>
        </div>
        <div className="desc">
          没有账号？ <Link to="/register">去注册</Link>
        </div>
        <div className="beian">
          <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">鄂ICP备2024037121号-2</a>
        </div>
        <AgreementModal
            isOpen={isAgreementOpen}
            onClose={() => setIsAgreementOpen(false)}
            initialTab={activeAgreementTab}
        />
      </div>
    </div>
  );
};

export default Login;