import React, { useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import loginTopImg from '../img/login-top.svg';
import loginButImg from '../img/login-but.svg';
import './Register.scss';

const Register = () => {
    const [err, setErr] = useState(false);
    const [registerErr, setRegisterErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const [verifyCode, setVerifyCode] = useState("");
    const [countdown, setCountdown] = useState(0);
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();
    const [resMessage, setResMessage] = useState("");
    const timerRef = useRef(null);

    const startCountdown = () => {
        setCountdown(20);
        if (timerRef.current) clearInterval(timerRef.current);
        timerRef.current = setInterval(() => {
            setCountdown(prev => {
                if (prev <= 1) {
                    clearInterval(timerRef.current);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    const getVerificationCode = async (e) => {
        e.preventDefault();
        const phoneRegex = /^1[3-9]\d{9}$/;

        if (!phone) {
            setErr(true);
            setResMessage("请输入手机号");
            return;
        }

        if (!phoneRegex.test(phone)) {
            setErr(true);
            setResMessage("请输入正确的手机号");
            return;
        }

        try {
            const response = await axios.get('/api/send_sms', {
                params: { phone },
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.data && response.data.message === "success") {
                setResMessage("验证码已发送");
                setErr(false);
                setRegisterErr(false);
                startCountdown();
            } else {
                setResMessage(response.data?.message || "获取验证码失败，请稍后重试");
                setRegisterErr(true);
            }
        } catch (error) {
            console.error("Get verification code error:", error);
            setResMessage(error.response?.data?.message || "获取验证码失败，请稍后重试");
            setRegisterErr(true);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        const phoneRegex = /^1[3-9]\d{9}$/;

        if (!phone || !password || !confirmPassword || !verifyCode) {
            setErr(true);
            setResMessage("请填写完整信息");
            setLoading(false);
            return;
        }

        if (!phoneRegex.test(phone)) {
            setErr(true);
            setResMessage("请输入正确的手机号");
            setLoading(false);
            return;
        }

        if (password !== confirmPassword) {
            setErr(true);
            setResMessage("两次输入的密码不一致");
            setLoading(false);
            return;
        }

        if (password.length < 6) {
            setErr(true);
            setResMessage("密码长度不能少于6位");
            setLoading(false);
            return;
        }

        setErr(false);

        try {
            const response = await axios.post('/api/register', {
                phone,
                password,
                smscode: verifyCode
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.data && response.data.message === "success") {
                setResMessage("注册成功，即将跳转到登录页面");
                setErr(false);
                setRegisterErr(false);
                setTimeout(() => {
                    navigate("/login");
                }, 2000);
            } else {
                setResMessage(response.data?.message || "注册失败，请稍后重试");
                setRegisterErr(true);
            }
        } catch (error) {
            console.error("Registration error:", error);
            setResMessage(error.response?.data?.message || "注册失败，请稍后重试");
            setRegisterErr(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="register">
            <div className="center">
                <div className="imgTop">
                    <img src={loginTopImg} alt="Login Top" className="top-img" />
                    <img src={loginButImg} alt="Login Button" className="but-img" />
                    <div className="title">眼波流转，一秒沉浸入梦</div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-item">
                        <label>手机号</label>
                        <input
                            type="tel"
                            placeholder="请输入手机号"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            disabled={loading}
                            className="custom-input"
                        />
                    </div>
                    <div className="form-item">
                        <label>密码</label>
                        <input
                            type="password"
                            placeholder="请输入密码"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={loading}
                            className="custom-input"
                        />
                    </div>
                    <div className="form-item">
                        <label>确认密码</label>
                        <input
                            type="password"
                            placeholder="请再次输入密码"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            disabled={loading}
                            className="custom-input"
                        />
                    </div>
                    <div className="form-item">
                        <label>验证码</label>
                        <div className="verify-code-box">
                            <input 
                                type="text" 
                                placeholder="请输入验证码"
                                value={verifyCode}
                                onChange={(e) => setVerifyCode(e.target.value)}
                                disabled={loading}
                                className="custom-input"
                            />
                            <button 
                                type="button"
                                className="verify-code-button"
                                onClick={getVerificationCode}
                                disabled={countdown > 0 || loading}
                            >
                                {countdown > 0 ? `${countdown}秒后重新获取` : "获取验证码"}
                            </button>
                        </div>
                    </div>
                    <div className="btnBox">
                        <button 
                            disabled={loading}
                            className="gradient-button"
                        >
                            {loading ? "注册中..." : "注册"}
                        </button>
                    </div>
                    {(err || registerErr) && <div className="error">{resMessage}</div>}
                    {!err && !registerErr && resMessage && <div className="info">{resMessage}</div>}
                </form>
                <div className="desc">
                    已经有账号？ <Link to="/login">请登录</Link>
                </div>
            </div>
        </div>
    );
};

export default Register;