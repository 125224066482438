import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from "../context/AxiosContext";
import backIcon from '../img/back.svg';
import './Feedback.scss';
import { useToast } from '../components/Toast';

const Feedback = () => {
  const navigate = useNavigate();
  const { ToastComponent, showToast } = useToast();
  const [form, setForm] = useState({
    content: '',
    image_base64: '',
    contact: ''
  });

  const handleImageUpload = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setForm(prev => ({
            ...prev,
            image_base64: event.target.result
          }));
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  const handleSubmit = async () => {
    if (!form.content.trim()) {
      showToast('请填写反馈描述', 'error');
      return;
    }

    try {
      await axiosInstance.post('/send_feedback', {
        content: form.content,
        image_base64: form.image_base64 || null,
        contact: form.contact || null
      });
      showToast('提交成功');
      setTimeout(() => {
        navigate("/my");
      }, 1500);
    } catch (error) {
      showToast('提交失败', 'error');
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => showToast('复制成功'))
      .catch(() => showToast('复制失败', 'error'));
  };

  return (
    <div className="feedback">
      <div className="navbar">
        <button className="back-button" onClick={() => navigate("/my")}>
          <img src={backIcon} alt="返回" />
        </button>
        <span>意见反馈</span>
      </div>

      <div className="formBox">
        <div className="fankui">
          <span className="required">*</span>
          反馈描述
        </div>
        <div className="fankuiyu">
          <textarea
            value={form.content}
            onChange={(e) => setForm(prev => ({ ...prev, content: e.target.value }))}
            placeholder="说说您的问题或者建议，以便我们提供更好的服务"
          />
        </div>

        <div className="uploading">上传图片(选填，提供问题截图)</div>
        <div className="tupian">
          {form.image_base64 ? (
            <img
              src={form.image_base64}
              alt="Uploaded"
              style={{ width: '68px' }}
              onClick={handleImageUpload}
            />
          ) : (
            <div className="jiahao" onClick={handleImageUpload}>
              <svg viewBox="0 0 24 24" className="plus-icon">
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
              </svg>
            </div>
          )}
        </div>

        <div className="lianxi">联系方式（选填）</div>
        <div className="xuantian">
          <input
            type="text"
            placeholder="说说您的问题或者建议，以便我们提供更好的服务"
            value={form.contact}
            onChange={(e) => setForm(prev => ({ ...prev, contact: e.target.value }))}
          />
        </div>
      </div>

      <div className="btn" onClick={handleSubmit}>提交</div>

      <div className="dibu">
        <div>有基他问题请欢迎在我们的小红书官方景留言</div>
        <div className="xiaohongshu">
          小红书号：
          <span>avaworld</span>
          <button className="copy-button" onClick={() => copyToClipboard('avawodd')}>
            <svg viewBox="0 0 24 24" className="copy-icon">
              <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
            </svg>
          </button>
        </div>
      </div>
      {ToastComponent}
    </div>
  );
};

export default Feedback;
