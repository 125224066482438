import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../context/AxiosContext';
import BottomNav from '../components/BottomNav';
import './Chat.scss';
import backIcon from '../img/back.svg';
import searchIcon from '../img/search.svg';

const Chat = () => {
  const [chatList, setChatList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChatList = async () => {
      try {
        const response = await axiosInstance.get('/get_chat_list');
        const { chat_list } = response.data;
        setChatList(chat_list);
      } catch (error) {
        console.error('Failed to fetch chat list:', error);
      }
    };

    fetchChatList();
  }, []);

  const handleChatClick = useCallback((chat) => {
    localStorage.setItem('chat_meta', JSON.stringify(chat));
    navigate('/chatroom', {
      state: {
        chat_mode: 'default'
      },
    });
  }, [navigate]);

  return (
    <div className="dialogue">
      <div className="header">
        <div className="title">对话列表</div>
        <div className="search">
          <img src={searchIcon} alt="搜索" onClick={() => navigate('/searchava')} />
        </div>
      </div>

      <div className="list">
        {chatList.map((chat) => (
          <div key={chat.relationship_id} className="item" onClick={() => handleChatClick(chat)}>
            <div className="l">
              <div className="photo">
                <img src={chat.ava_avatar} alt={chat.ava_name} />
              </div>
              <div className="msgBox">
                <div className="name">{chat.ava_name}</div>
                <div className="msg">{chat.last_message}</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <BottomNav />
    </div>
  );
};

export default Chat;