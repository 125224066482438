import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axiosInstance from '../context/AxiosContext';
import backIcon from '../img/back.svg';
import likedIcon from '../img/liked.svg';
import unlikedIcon from '../img/unliked.svg';
import Masonry from 'react-masonry-css';
import { debounce } from '@mui/material';
import './AvaDetail.scss';

const AvaDetail = () => {
  const [searchParams] = useSearchParams();
  const ava_id = searchParams.get('ava_id');
  const navigate = useNavigate();
  const [breakpointCols, setBreakpointCols] = useState({
    default: 1,
    1100: 1,
    700: 1
  });
  const [avaMeta, setAvaMeta] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [tabs] = useState([
    { name: '角色简介' },
    { name: '剧场' }
  ]);
  const [theaterList, setTheaterList] = useState([]);

  useEffect(() => {
    const storedAvaMeta = localStorage.getItem('avaMeta');
    console.log(storedAvaMeta);
    if (storedAvaMeta) {
      setAvaMeta(JSON.parse(storedAvaMeta));
    }
  }, []);

  // 获取剧场列表
  const getTheaterList = async () => {
    try {
      const response = await axiosInstance.get(`/get_theater_list?ava_id=${ava_id}`);
      if (response.data) {
        setTheaterList(response.data.results);
      }
    } catch (error) {
      console.error('Failed to get theater list:', error);
    }
  };

  // 处理点赞/取消点赞
  const handleLike = async (theaterId) => {
    // 先更新本地状态
    setTheaterList(prevList => {
      return prevList.map(item => {
        if (item.theater_id === theaterId) {
          return {
            ...item,
            liked: item.liked === 1 ? 0 : 1,
            like_num: item.like_num + (item.liked === 1 ? -1 : 1)
          };
        }
        return item;
      });
    });

    // 调用点赞API
    try {
      await axiosInstance.get(`/press_like_theater?theater_id=${theaterId}`);
    } catch (error) {
      console.error('Failed to like theater:', error);
      // 如果API调用失败，回滚状态
      setTheaterList(prevList => {
        return prevList.map(item => {
          if (item.theater_id === theaterId) {
            return {
              ...item,
              liked: item.liked === 1 ? 0 : 1,
              like_num: item.like_num + (item.liked === 1 ? -1 : 1)
            };
          }
          return item;
        });
      });
    }
  };

  // 处理聊天按钮点击
  const handleChat = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/add_ava', {
        params: { ava_id: avaMeta.ava_id },
      });
      localStorage.setItem('chat_meta', JSON.stringify({
        ...response.data.result,
      }));
      navigate("/chatroom", {
        state: {
          chat_mode: 'default'
        }
      });
    } catch (error) {
      console.error('Failed to add Ava:', error);
    }
  }, [navigate, avaMeta]);

  // 当切换到剧场tab时获取列表
  useEffect(() => {
    if (activeTab === 1) {
      getTheaterList();
    }
  }, [activeTab]);

  useEffect(() => {
      const updateLayout = debounce(() => {
        const aspectRatio = window.innerWidth / window.innerHeight;
        
        // 竖屏设备 (aspectRatio < 0.8)
        if (aspectRatio < 0.8) {
          setBreakpointCols({
            default: 1,
            1100: 1,
            700: 1
          });
        }
        // 平板设备 (0.8 <= aspectRatio < 1.3)
        else if (aspectRatio < 1.3) {
          setBreakpointCols({
            default: 2,
            1100: 2,
            700: 1
          });
        }
        // 横屏设备 (aspectRatio >= 1.3)
        else {
          setBreakpointCols({
            default: 3,
            1100: 2,
            700: 1
          });
        }
      }, 500);
      updateLayout();
      window.addEventListener('resize', updateLayout);
      return () => window.removeEventListener('resize', updateLayout);
    }, []);

  const onScroll = (e) => {
    const scrollTop = e.target.scrollTop;
  };

  return (
    <div className="roleDetail">
      <div className="header">
        <div className="back" onClick={() => navigate(-1)}>
          <img src={backIcon} alt="返回" />
        </div>
      </div>

      <div className="scroll-view" onScroll={onScroll}>
        <div className="bg-video">
          <video
            className="video"
            src={avaMeta.ava_bg_pic}
            autoPlay
            muted
            loop
            playsInline
            webkit-playsinline="true"
            x5-playsinline="true"
            x5-video-player-type="h5"
            x5-video-player-fullscreen="false"
            x5-video-orientation="portraint"
            disablePictureInPicture
            controlsList="nodownload nofullscreen noremoteplayback"
          />
        </div>
        <div className="profile-content">
          <div className="ava-profile">
            <div className="avatar">
              <img src={avaMeta.ava_avatar} alt="avatar" />
            </div>
            <div className="info">
              <div className="name">{avaMeta.ava_name}</div>
              <div className="creator">
                创作者: <span>@{avaMeta.create_user_name}</span>
              </div>
            </div>
          </div>
          <div className="tabs-section">
            <div className="tabs">
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  className={`tab ${activeTab === index ? 'active' : ''}`}
                  onClick={() => setActiveTab(index)}
                >
                  {tab.name}
                </div>
              ))}
            </div>

            <div className="tab-content">
              {activeTab === 0 ? (
                <div className="intro">
                  <div className="intro-text">{avaMeta.ava_intro}</div>
                </div>
              ) : (
                <Masonry className="theater masonry-grid" breakpointCols={breakpointCols} columnClassName="masonry-grid_column">
                  {theaterList.map((item, index) => (
                    <div className="item" key={index}>
                      <div className="title">{item.title}</div>
                      <div className="time">
                        <div>
                          创作者: <span style={{ color: '#FF69B4', fontFamily:'PingFang Medium'}}>@{item.user_name}</span>
                        </div>
                        <div>{item.add_time}</div>
                      </div>
                      <div className="fm">
                        <video
                          className="video"
                          autoPlay
                          muted
                          src={item.video_url}
                          playsInline
                          controls={false}
                          webkit-playsinline="true"
                          x5-playsinline="true"
                          x5-video-player-type="h5"
                          x5-video-player-fullscreen="false"
                          x5-video-orientation="portraint"
                          disablePictureInPicture
                          controlsList="nodownload nofullscreen noremoteplayback"
                        />
                        <div className="xihuan" onClick={() => handleLike(item.theater_id)}>
                          <div className="xihuanaixin">
                            {item.liked === 1 ? (
                              <img src={likedIcon} alt="liked" />
                            ) : (
                              <img src={unlikedIcon} alt="unliked" />
                            )}
                          </div>
                          {item.like_num}人喜欢
                        </div>
                      </div>
                    </div>
                  ))}
                </Masonry>
              )}
            </div>
          </div>
        </div>

        <button 
          className="gradient-button"
          onClick={handleChat}
        >
          去聊天
        </button>
      </div>
    </div>
  );
};

export default AvaDetail;
