import React, { useState, useEffect } from 'react';
import './AgreementModal.scss';

// 导入 Markdown 文件内容
import userAgreementMd from './用户条款.md';
import privacyPolicyMd from './隐私政策.md';

// 简单的markdown转HTML函数
const convertMdToHtml = (markdown) => {
    if (!markdown) return '';
    
    // 处理标题
    let html = markdown
        .replace(/^# (.*$)/gm, '<h1>$1</h1>')
        .replace(/^## (.*$)/gm, '<h2>$1</h2>')
        .replace(/^### (.*$)/gm, '<h3>$1</h3>')
        .replace(/^#### (.*$)/gm, '<h4>$1</h4>');
    
    // 处理粗体
    html = html.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    
    // 处理列表
    html = html.replace(/^\s*[-*+]\s+(.*$)/gm, '<li>$1</li>');
    html = html.replace(/(<li>.*<\/li>\n)+/g, '<ul>$&</ul>');
    
    // 处理数字列表
    html = html.replace(/^\s*\d+\.\s+(.*$)/gm, '<li>$1</li>');
    html = html.replace(/(<li>\d+\..*<\/li>\n)+/g, '<ol>$&</ol>');
    
    // 处理空行为段落
    html = html.replace(/^(?!<[hol])(.*$)/gm, function(match) {
        return match.trim() ? '<p>' + match + '</p>' : '';
    });
    
    // 处理水平线
    html = html.replace(/^---$/gm, '<hr>');

    return html;
};

const AgreementModal = ({ isOpen, onClose, initialTab = 'agreement' }) => {
    const [activeTab, setActiveTab] = useState(initialTab);
    const [agreementContent, setAgreementContent] = useState('');
    const [privacyContent, setPrivacyContent] = useState('');

    useEffect(() => {
        // 读取 Markdown 文件内容
        fetch(userAgreementMd)
            .then(response => response.text())
            .then(text => setAgreementContent(convertMdToHtml(text)))
            .catch(error => console.error('Error loading user agreement:', error));

        fetch(privacyPolicyMd)
            .then(response => response.text())
            .then(text => setPrivacyContent(convertMdToHtml(text)))
            .catch(error => console.error('Error loading privacy policy:', error));
    }, []);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <div className="tabs">
                        <button 
                            className={`tab ${activeTab === 'agreement' ? 'active' : ''}`}
                            onClick={() => setActiveTab('agreement')}
                        >
                            AVAWorld用户条款
                        </button>
                        <button 
                            className={`tab ${activeTab === 'privacy' ? 'active' : ''}`}
                            onClick={() => setActiveTab('privacy')}
                        >
                            AVAWorld隐私政策
                        </button>
                    </div>
                    <button className="close-button" onClick={onClose}>×</button>
                </div>
                <div className="modal-body">
                    {activeTab === 'agreement' ? (
                        <div dangerouslySetInnerHTML={{ __html: agreementContent }} />
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html: privacyContent }} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AgreementModal;