import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import { useSwipeable } from 'react-swipeable';
import axiosInstance from '../context/AxiosContext';
import '../pages/Browse.scss';
import BottomNav from '../components/BottomNav';
import searchIcon from '../img/search.svg';
import { debounce } from '@mui/material';

const Browse = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [avaList, setAvaList] = useState([]);
  const [tabs, setTabs] = useState(['全部']);
  const [loading, setLoading] = useState(true);
  const [breakpointCols, setBreakpointCols] = useState({
    default: 1,
    1100: 1,
    700: 1
  });
  const [bottomPadding, setBottomPadding] = useState('150px');
  const navigate = useNavigate();

  useEffect(() => {
    // 设置vh变量来解决iOS的100vh问题
    const setVH = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setVH();
    window.addEventListener('resize', setVH);
    return () => window.removeEventListener('resize', setVH);
  }, []);

  useEffect(() => {
    const updateLayout = debounce(() => {
      const aspectRatio = window.innerWidth / window.innerHeight;
      
      // 竖屏设备 (aspectRatio < 0.8)
      if (aspectRatio < 0.8) {
        setBreakpointCols({
          default: 1,
          1100: 1,
          700: 1
        });
        setBottomPadding('50px');
      }
      // 平板设备 (0.8 <= aspectRatio < 1.3)
      else if (aspectRatio < 1.3) {
        setBreakpointCols({
          default: 2,
          1100: 2,
          700: 1
        });
        setBottomPadding('70px');
      }
      // 横屏设备 (aspectRatio >= 1.3)
      else {
        setBreakpointCols({
          default: 3,
          1100: 2,
          700: 1
        });
        setBottomPadding('90px');
      }
    }, 300);
    updateLayout();
    window.addEventListener('resize', updateLayout);
    return () => window.removeEventListener('resize', updateLayout);
  }, []);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axiosInstance.get('/get_tags');
        setTabs(['全部', ...(response.data.tags || [])]);
      } catch (error) {
        console.error('获取标签失败:', error);
        setTabs(['全部']); // Ensure tabs always has at least the default option
      } finally {
        setLoading(false);
      }
    };

    fetchTags();
  }, []);

  useEffect(() => {
    const fetchFilteredAva = async () => {
      try {
        const response = await axiosInstance.get('/filter_ava', {
          params: { tag: tabs[activeTab] },
        });
        console.log('Filtered Ava:', response.data);
        setAvaList(response.data.results || []);
      } catch (error) {
        console.error('Failed to fetch filtered Ava:', error);
        setAvaList([]); // Ensure avaList is set to an empty array on error
      }
    };

    if (!loading) {
      fetchFilteredAva();
    }
  }, [activeTab, tabs, loading]);

  const handleGreeting = useCallback(async (ava) => {
    try {
      const response = await axiosInstance.get('/add_ava', {
        params: { ava_id: ava.ava_id },
      });
      localStorage.setItem('chat_meta', JSON.stringify({
        ...response.data.result,
      }));
      navigate("/chatroom", {
        state: {
          chat_mode: 'default'
        }
      });
    } catch (error) {
      console.error('Failed to add Ava:', error);
    }
  }, [navigate]);

  const handlers = useSwipeable({
    onSwipedUp: (eventData) => {
      const currentIndex = avaList.findIndex(ava => ava.ava_id === eventData.event.target.closest('.cardUser').dataset.avaId);
      if (currentIndex < avaList.length - 1) {
        // 移除自动滑动到顶部的功能
        // document.querySelector(`[data-ava-id="${avaList[currentIndex + 1].ava_id}"]`)?.scrollIntoView({ behavior: 'smooth' });
      }
    },
    onSwipedDown: (eventData) => {
      const currentIndex = avaList.findIndex(ava => ava.ava_id === eventData.event.target.closest('.cardUser').dataset.avaId);
      if (currentIndex > 0) {
        // 移除自动滑动到顶部的功能
        // document.querySelector(`[data-ava-id="${avaList[currentIndex - 1].ava_id}"]`)?.scrollIntoView({ behavior: 'smooth' });
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: false
  });

  return (
    <div className="content">
      <div className="header">
        <div className="shousuolan">
          {tabs.map((tab, index) => (
            <div
              key={tab}
              className={`tab ${activeTab === index ? 'tabActive' : ''}`}
              onClick={() => {
                setAvaList([]);
                setActiveTab(index);
              }}
            >
              <div className="tabziyuansu">{tab}</div>
            </div>
          ))}
        </div>
        <div className="search">
          <img src={searchIcon} alt="search" onClick={() => navigate('/searchava')} />
        </div>
      </div>

      <div 
        className="content-scroll" 
        {...handlers}
        style={{ padding: `0 5px ${bottomPadding}` }}
      >
        <Masonry
          breakpointCols={breakpointCols}
          className="masonry-grid"
          columnClassName="masonry-grid_column"
        >
          {(avaList || []).map((ava) => (
            <div key={ava.ava_id} className="cardUser" data-ava-id={ava.ava_id}>
              <div className="bgc">
                <video
                  autoPlay
                  muted
                  loop
                  controls={false}
                  src={ava.ava_bg_pic}
                  playsInline
                  loading="lazy"
                  poster={ava.ava_preview}
                  webkit-playsinline="true"
                  x5-playsinline="true"
                  x5-video-player-type="h5"
                  x5-video-player-fullscreen="false"
                  x5-video-orientation="portraint"
                  disablePictureInPicture
                  controlsList="nodownload nofullscreen noremoteplayback"
                />
                <div className="infotop">
                  <div className="infotopzuo">
                    <div className="title">{ava.ava_descr}</div>
                    <div className="tags-container">
                      <div className="tags">
                        {ava.ava_subtags?.map((tag, index) => (
                          <div key={index} className="tag">#{tag}</div>
                        ))}
                      </div>
                      <div className="greeting">
                        <div 
                          className="btn" 
                          onClick={() => handleGreeting(ava)}
                        >
                          打招呼
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="name">
                <p>{ava.ava_name}</p>
                <p className="lianjie">已进行{ava.friend_num}次连接</p>
              </div>
              <div className="status">
                <span>{ava.is_friend ? '已连接' : '未连接'}</span>
              </div>
              <div className="info">
                <div className="infobottom">
                  <div className="desc">{ava.ava_intro}</div>
                  <div className="more" onClick={() => {
                    localStorage.setItem('avaMeta', JSON.stringify(ava));
                    navigate(`/avadetail?ava_id=${ava.ava_id}`);
                  }}>
                    MORE
                    <span>→</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Masonry>
      </div>

      <BottomNav />
    </div>
  );
};

export default Browse;