import React, { useCallback, useEffect, useState } from 'react';
import { BottomNavigation as MuiBottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';

// Import icons
import chatIcon from '../img/tabbar/chat.svg';
import browseIcon from '../img/tabbar/browse.svg';
import myIcon from '../img/tabbar/my.svg';
import onChatIcon from '../img/tabbar/onchat.svg';
import onBrowseIcon from '../img/tabbar/onbrowse.svg';
import onMyIcon from '../img/tabbar/onmy.svg';

const StyledBottomNavigation = styled(MuiBottomNavigation)({
  backgroundColor: 'rgba(28, 28, 46, 1)',
  height: 60,
  '& .MuiBottomNavigationAction-root': {
    color: 'rgba(104, 104, 114, 1)',
    minWidth: 'auto',
    padding: '8px 12px',
    '&.Mui-selected': {
      color: 'rgba(215, 45, 156, 1)',
    },
  },
  '& .MuiBottomNavigationAction-label': {
    fontFamily: 'PingFang SC, -apple-system, Roboto, Helvetica, sans-serif',
    fontSize: '12px !important',
    marginTop: 4,
  },
});

const StyledPaper = styled(Paper)({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  backgroundColor: 'transparent',
  boxShadow: 'none',
});

// 预加载所有图标
const preloadImage = (src) => {
  const img = new Image();
  img.src = src;
};

const BottomNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState('/browse');

  // 预加载所有图标
  useEffect(() => {
    [chatIcon, browseIcon, myIcon, onChatIcon, onBrowseIcon, onMyIcon].forEach(preloadImage);
  }, []);

  // 根据当前路径更新选中状态
  useEffect(() => {
    const path = location.pathname;
    if (['/browse', '/chat', '/my'].includes(path)) {
      setValue(path);
    }
  }, [location]);

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
    navigate(newValue, { replace: true });
  }, [navigate]);

  const navItems = [
    { path: '/chat', label: '对话', icon: chatIcon, activeIcon: onChatIcon },
    { path: '/browse', label: '浏览', icon: browseIcon, activeIcon: onBrowseIcon },
    { path: '/my', label: '我的', icon: myIcon, activeIcon: onMyIcon },
  ];

  return (
    <StyledPaper elevation={3}>
      <StyledBottomNavigation
        value={value}
        onChange={handleChange}
        showLabels
      >
        {navItems.map(({ path, label, icon, activeIcon }) => (
          <BottomNavigationAction
            key={path}
            label={label}
            value={path}
            icon={
              <img
                src={path === value ? activeIcon : icon}
                alt=""
                style={{ width: 24, height: 24 }}
              />
            }
          />
        ))}
      </StyledBottomNavigation>
    </StyledPaper>
  );
};

export default React.memo(BottomNav);