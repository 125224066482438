import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../context/AxiosContext';
import backIcon from '../img/back.svg';
import shortLine from '../img/shortline.svg';
import './Circum.scss';

const Circum = () => {
  const navigate = useNavigate();
  const chatMeta = JSON.parse(localStorage.getItem('chat_meta'));
  const [info, setInfo] = useState({
    relationship_id: chatMeta?.relationship_id || '',
    system_content: '',
    defaultcircum_id: '',
    bg_pic: '',
    ava_first_content: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef(null);
  
  const createText = async () => {
    try {
      const response = await axiosInstance.get('/generate_circum');
      if (response.data && response.data.circum_meta) {
        const circum_meta = response.data.circum_meta;
        setInfo(prev => ({
          ...prev,
          system_content: circum_meta.system_content,
          defaultcircum_id: circum_meta.defaultcircum_id,
          bg_pic: circum_meta.bg_pic,
          ava_first_content: circum_meta.ava_first_content
        }));
      }
    } catch (error) {
      console.error('Failed to generate circum:', error);
    }
  };

  useEffect(() => {
    createText();
  }, []);

  useEffect(() => {
    if (videoRef.current && info.bg_pic) {
      videoRef.current.load();
    }
  }, [info.bg_pic]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true); // 开始加载
      const response = await axiosInstance.post('/save_circum', {
        relationship_id: parseInt(info.relationship_id),
        system_content: info.system_content,
        defaultcircum_id: info.defaultcircum_id ? parseInt(info.defaultcircum_id) : null
      });

      if (response.data && response.data.circum_meta) {
        localStorage.setItem('circum_meta', JSON.stringify(response.data.circum_meta));
        navigate('/chatroom', {state: {
          chat_mode: 'circum'
        }});
      }
    } catch (error) {
      console.error('Failed to save circum:', error);
    } finally {
      setIsLoading(false); // 结束加载
    }
  };

  return (
    <div className="condition">
      <div className="header">
        <div className="back" onClick={() => navigate(-1)}>
          <img src={backIcon} alt="返回" />
        </div>
        <div className="title">心动情况</div>
        <div className="placeholder"></div>
      </div>
      
      <div className="desc">选择让你心动的场景，开始对话吧！</div>
      
      <div className="content">
        <video
          ref={videoRef}
          autoPlay
          muted
          loop
          playsInline
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 1
          }}
          src={info.bg_pic ? `${info.bg_pic}?t=${Date.now()}` : ''}
        />
        <div className="text-content">
          <div className="contxt">
            <div className="text">{info.system_content}</div>
          </div>
          <div className="line"></div>
          <div className="chat">
            <span>{info.ava_first_content}</span>
          </div>
        </div>
      </div>

      <div className="update" onClick={createText}>
        <span className="icon" style={{ marginRight: '10px' }}>↻</span>
        <span className="text">换一换</span>
      </div>

      <div 
        className={`bomBtn ${isLoading ? 'disabled' : ''}`} 
        onClick={!isLoading ? handleSubmit : undefined}
      >
        {isLoading ? '加载中...' : '用这种情形对话'}
      </div>
    </div>
  );
};

export default Circum;
