import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance, { WssUrl } from '../context/AxiosContext';
import { useAudio } from '../context/AudioContext';
import { useToast } from '../components/Toast';
import backIcon from '../img/back.svg';
import './ChatRoom.scss';
import subMenuIcon from '../img/submenu.svg';
import sendIcon from '../img/send.svg';
import tipsIcon from '../img/hint.svg';
import onGroupIcon from '../img/onGroup.svg';
import circumIcon from '../img/circum.svg';
import newDialogIcon from '../img/newdialog.svg';
import theaterIcon from '../img/theater.svg';
import secretIcon from '../img/secret.svg';
import audioIcon from '../img/audio.svg';
import shortLineIcon from '../img/shortline.svg';
import nextIcon from '../img/next.svg';
import { sleep, setCaret } from '../utils/index.ts';
import ReactPlayer from 'react-player';
let isFocus = false;
const ChatRoom = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [chatMode, setChatMode] = useState(location.state?.chat_mode || 'default');
  const setAndSaveChatMode = (mode) => {
    setChatMode(mode);
    location.state = {
      chat_mode: mode
    };
  };
  const [messageList, setMessageList] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [showTips, setShowTips] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [isSecret, setIsSecret] = useState(false);
  const [tipsList, setTipsList] = useState([]);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [posterImage, setPosterImage] = useState('');
  const [isAudioAutoPlay, setIsAudioAutoPlay] = useState(true);
  const [pendingAvaMessages, setPendingAvaMessages] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [userSettings, setUserSettings] = useState({
    user_nickname: '',
    user_whoami: '',
    user_isfemale: 0
  });
  const [isSaving, setIsSaving] = useState(false);
  const [socketWsChat, setSocketWsChat] = useState(null);
  const [socketWsChatBackground, setSocketWsChatBackground] = useState(null);
  const [backgroundVideo, setBackgroundVideo] = useState('');
  const [isBackgroundVideoPreloaded, setIsBackgroundVideoPreloaded] = useState(false);
  const [bgVideoEnded, setBgVideoEnded] = useState(false);
  const [statusTitle, setStatusTitle] = useState('');
  const [videoRenderReady, setVideoRenderReady] = useState(false);
  const { playingMessageId, playAudio } = useAudio();
  const scrollViewRef = useRef(null);
  const chatMeta = JSON.parse(localStorage.getItem('chat_meta'));
  const [circum_meta, setCircumMeta] = useState(JSON.parse(localStorage.getItem('circum_meta')));
  const [form, setForm] = useState({
    user_nickname: '',
    user_whoami: '',
    user_isfemale: 0
  });
  const [showTheaterPanel, setShowTheaterPanel] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const { ToastComponent, showToast, hideToast } = useToast();
  const messageInput = useRef();

  const menuButtons = useMemo(() => [
    {
      label: <div style={{textAlign: 'center', color: '#C5C5D1', fontSize: 14, fontFamily: 'PingFang SC', fontWeight: '400', wordWrap: 'break-word'}}>情况</div>,
      index: 1,
      icon: circumIcon,
      onClick: () => {
        // setChatMode('circum');
        // localStorage.setItem('chat_meta', JSON.stringify({
        //   ...chatMeta,
        //   chat_mode: 'circum'
        // }));
        // checkAndAddSeparator('circum');
        navigate('/circum');
        setShowMenu(false);
      }
    },
    {
      label: <div style={{textAlign: 'center', color: '#C5C5D1', fontSize: 14, fontFamily: 'PingFang SC', fontWeight: '400', wordWrap: 'break-word'}}>新对话</div>,
      index: 2,
      icon: newDialogIcon,
      onClick: () => {
        navigate('/chat');
        setShowMenu(false);
      }
    },
    {
      label: <div style={{textAlign: 'center', color: '#C5C5D1', fontSize: 14, fontFamily: 'PingFang SC', fontWeight: '400', wordWrap: 'break-word'}}>剧场</div>,
      index: 3,
      icon: theaterIcon,
      onClick: () => {
        setShowButtons(true);  // 显示消息选择框
        setShowTheaterPanel(true); // 显示底部面板
        setShowMenu(false);
      }
    },
    {
      label: <div style={{textAlign: 'center', color: '#C5C5D1', fontSize: 14, fontFamily: 'PingFang SC', fontWeight: '400', wordWrap: 'break-word'}}>秘密之地</div>,
      index: 4,
      icon: secretIcon,
      onClick: async () => {
        if (isSecret) {
          showToast('已是秘密之地');
          return;
        }
        try {
          const { data } = await axiosInstance.get('/check_subscribe');
          if (data.result === 1) {
            // 会员
            const newIsSecret = !isSecret;
            setIsSecret(newIsSecret);
            const newMode = newIsSecret ? 'secret' : 'default';
            setAndSaveChatMode(newMode);
            checkAndAddSeparator(newMode);
            setShowMenu(false);
            const { data } = await axiosInstance.get('/get_chat_meta', {
              params: {
                chat_mode: newMode,
                ava_id: chatMeta.ava_id,
              }
            });
            
            if (data.result) {
              setCircumMeta(data.result);
              localStorage.setItem('circum_meta', JSON.stringify(data.result));
            }
          } else {
            navigate('/premium');
          }
        } catch (error) {
          console.error(error);
          showToast('接口请求失败')
        }
      }
    }
  ]);

  // 添加一个检查是否需要添加分隔线的函数
  const checkAndAddSeparator = (newMode) => {
    if (messageList.length === 0) return;
    
    const lastMessage = messageList[messageList.length - 1];
    if (lastMessage.type === 'separator') return;
    
    if (lastMessage.chat_mode !== newMode) {
      setMessageList(prev => [...prev, {
        type: 'separator',
        id: `separator-${lastMessage.chat_mode}-${newMode}-${Date.now()}`,
        icon: shortLineIcon
      }]);
      scrollToBottom();
    }
  };

  const handleSidebarToggle = () => {
    setShowSidebar(!showSidebar);
  };

  const handleSettingsSave = async () => {
    setIsSaving(true);
    try {
      // TODO: Save settings to backend
      await new Promise(resolve => setTimeout(resolve, 500)); // 模拟保存
      setShowSidebar(false);
    } finally {
      setIsSaving(false);
    }
  };

  const scrollToBottom = async () => {
    await sleep();
    if (scrollViewRef.current) {
      const scrollView = scrollViewRef.current;
      scrollView.scrollTo({
        top: scrollView.scrollHeight,
        behavior: 'auto'
      });
    }
  };

  // 监听messageList变化，只在新消息时自动滚动到底部
  useEffect(() => {
    if (messageList.length > 0) {
      const lastMessage = messageList[messageList.length - 1];
      // 只有当最后一条消息是新消息时才滚动（不是更新音频URL）
      if (!lastMessage.audio_url || lastMessage.role === 'user') {
        requestAnimationFrame(() => {
          scrollToBottom();
        });
      }
    }
  }, [messageList]);

  useEffect(() => {
    fetchChatMessageList();
    initWsChat();
    initWsChatBackground();

    return () => {
      // 组件卸载时关闭websocket连接
      if (socketWsChat) {
        socketWsChat.close();
        setSocketWsChat(null);
      }
      if (socketWsChatBackground) {
        socketWsChatBackground.close();
        setSocketWsChatBackground(null);
      }
    };
  }, []);

  // 初始化聊天 WebSocket
  const initWsChat = () => {
    const socket_ws_chat = new WebSocket(`${WssUrl}/api/ws_chat`);
    const token = localStorage.getItem('access_token');

    socket_ws_chat.onopen = () => {
      console.log('ws_chat open');
      socket_ws_chat.send(JSON.stringify({ token: token }));
    };

    socket_ws_chat.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Received message data:', data);

      if ("status" in data) {
        setStatusTitle(data.status);
        socket_ws_chat.send(JSON.stringify({ "status": "received" }));
      } else {
        // 确保消息包含所需字段
        const messageData = {
          ...data,
          content_type: data.content_type || 'text',
          text: data.text || '',
          audio_url: data.audio_url || '',
          chat_mode: chatMode,
          isNewMessage: true // 标记为新消息
        };
        
        setMessageList((prevMessages) => [...prevMessages, messageData]);

        // 如果是ava的新消息且开启了自动播放，添加到待播放队列
        if (messageData.role === 'ava' && isAudioAutoPlay) {
          setPendingAvaMessages(prev => [...prev, messageData]);
        }
        scrollToBottom();
      }
    };

    socket_ws_chat.onerror = (error) => {
      console.error('ws_chat error:', error);
    };

    socket_ws_chat.onclose = () => {
      console.log("ws_chat closed");
    };

    setSocketWsChat(socket_ws_chat);
  };

  // 初始化背景 WebSocket
  const initWsChatBackground = () => {
    const socket_ws_chat_background = new WebSocket(`${WssUrl}/api/ws_chat_background`);
    const token = localStorage.getItem('access_token');
    
    socket_ws_chat_background.onopen = () => {
      console.log('ws_chat_background open');
      socket_ws_chat_background.send(JSON.stringify({ 
        token: token,
        chat_meta: chatMeta,
        chat_mode: chatMode
      }));
    };

    socket_ws_chat_background.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Received background data:', data);
      
      if (data.background_url) {
        // 重置视频准备状态
        setVideoRenderReady(false);
        // 先保持旧背景可见
        setIsBackgroundVideoPreloaded(false);
        // 更新背景视频URL
        console.log("正在设置背景视频URL:", data.background_url);
        setBackgroundVideo(data.background_url);
        setBgVideoEnded(false);
        console.log("设置了背景视频URL:", data.background_url);
        
        // 直接测试URL是否可访问
        const testVideo = document.createElement('video');
        testVideo.muted = true;
        testVideo.src = data.background_url;
        testVideo.onerror = () => {
          console.error("背景视频URL无法加载:", data.background_url);
        };
        testVideo.onloadeddata = () => {
          console.log("背景视频URL可以成功加载:", data.background_url);
        };
        
        // 发送received状态
        socket_ws_chat_background.send(JSON.stringify({
          status: 'received'
        }));
      }
    };

    socket_ws_chat_background.onerror = (error) => {
      console.error('ws_chat_background error:', error);
    };

    socket_ws_chat_background.onclose = () => {
      console.log("ws_chat_background closed");
    };

    setSocketWsChatBackground(socket_ws_chat_background);
  };

  const handleSendMessage = (tip) => {
    tip = tip || inputMessage.trim();
    if (!tip) return;
    
    if (socketWsChat && socketWsChat.readyState === WebSocket.OPEN) {
      const messageData = {
        text: tip,
        chat_mode: chatMode,
        chat_meta: chatMeta
      };
      
      // 添加消息到本地消息列表
      const localMessage = {
        ...messageData,
        content_type: 'text',
        is_user: true, // 标记为用户消息
        id: Date.now(), // 临时ID
        created_at: new Date().toISOString()
      };
      setMessageList(prevMessages => [...prevMessages, localMessage]);
      
      socketWsChat.send(JSON.stringify(messageData));
      setInputMessage('');
      setShowTips(false);
      scrollToBottom();
    } else {
      console.error('WebSocket is not connected');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const fetchChatMessageList = async () => {
    try {
      const modes = ['default', 'circum', 'secret'];
      let allMessages = [];
      
      for (const mode of modes) {
        const response = await axiosInstance.get('/get_chat_message_list', {
          params: { 
            ava_id: chatMeta.ava_id,
            chat_mode: mode
          }
        });
        
        if (response.data.message === 'success' && response.data.results.length > 0) {
          const messages = response.data.results.map(msg => ({
            ...msg,
            chat_mode: mode,
            isNewMessage: false // 标记为历史消息
          }));
          allMessages = [...allMessages, ...messages];
        }
      }

      // 按add_time顺序排序所有消息
      allMessages.sort((a, b) => new Date(a.add_time) - new Date(b.add_time));

      // 在不同chat_mode和circum_id的消息之间插入分隔线
      const finalMessages = [];
      for (let i = 0; i < allMessages.length; i++) {
        finalMessages.push(allMessages[i]);
        const nextMsg = allMessages[i + 1];
        if (nextMsg) {
          const currentMsg = allMessages[i];
          const nextMsg = allMessages[i + 1];
          // 不同chat_mode之间添加分隔线
          if (currentMsg.chat_mode !== nextMsg.chat_mode) {
            finalMessages.push({
              type: 'separator',
              id: `separator-${currentMsg.chat_mode}-${nextMsg.chat_mode}-${i}`,
              icon: shortLineIcon
            });
          }
          // 在circum模式下，不同circum_id之间添加分隔线
          else if (currentMsg.chat_mode === 'circum' && currentMsg.circum_id !== nextMsg.circum_id) {
            finalMessages.push({
              type: 'separator',
              id: `separator-circum-${currentMsg.circum_id}-${nextMsg.circum_id}-${i}`,
              icon: shortLineIcon
            });
          }
        }
      }
      // 如果最后一条消息的chat_mode与当前chat_mode不一致，添加分隔线
      if (finalMessages.length > 0) {
        const lastMessage = finalMessages[finalMessages.length - 1];
        if (lastMessage.type !== 'separator' && lastMessage.chat_mode !== chatMode) {
          finalMessages.push({
            type: 'separator',
            id: `separator-${lastMessage.chat_mode}-${chatMode}-${Date.now()}`,
            icon: shortLineIcon
          });
        }
      }

      setMessageList(finalMessages);
      requestAnimationFrame(() => {
        scrollToBottom();
      });
    } catch (error) {
      console.error('Failed to fetch chat list:', error);
    }
  };

  const fetchChatCandidateList = async () => {
    showToast('loading...')
    try {
      const response = await axiosInstance.get('/get_chat_candidate_list', {
        params: { ava_id: chatMeta.ava_id, chat_mode: chatMode }
      });
      if (response.data.message === 'success') {
        setTipsList(response.data.results || []);
      }
    } catch (error) {
      console.error('Failed to fetch candidate list:', error);
    } finally {
      hideToast();
    }
  };

  const handleBackClick = () => {
    // 清理WebSocket连接
    if (socketWsChat) {
      socketWsChat.close();
      setSocketWsChat(null);
    }
    if (socketWsChatBackground) {
      socketWsChatBackground.close();
      setSocketWsChatBackground(null);
    }
    navigate(-1);
  };

  const handleTipClick = async () => {
    await fetchChatCandidateList();
    setShowTips(!showTips);
  };
  const handleActionClick = () => {
    isFocus = true;
    const message = inputMessage + '（）';
    messageInput.current.value = message;
    setCaret(messageInput.current, message.length - 1);
    setTimeout(() => {
      // messageInput.current.dispatchEvent(new Event('change'));
      isFocus = false;
      messageInput.current?.focus();
    }, 800)
  }

  const handleAddClick = () => {
    setShowMenu(!showMenu);
  };

  const handleCheckboxChange = (recordId) => {
    setSelectedMessages(prev => {
      if (prev.includes(recordId)) {
        return prev.filter(id => id !== recordId);
      } else if (prev.length < 20) {
        return [...prev, recordId];
      }
      return prev;
    });
  };

  const handleGenerateTheater = async () => {
    if (selectedMessages.length === 0) return;
    // 需要检查这些聊天记录是否都是一个chat_mode
    const chatModeCount = new Set();
    selectedMessages.forEach(recordId => {
      const message = messageList.find(msg => msg.record_id === recordId);
      if (message) {
        chatModeCount.add(message.chat_mode);
      }
    });

    if (chatModeCount.size > 1) {
      showToast('只能选择一种模式下的聊天消息，才能生成剧情');
      return;
    }
    
    setIsGenerating(true);
    showToast('生成视频可能需要1-5分钟');

    try {
      // 构建请求参数
      const params = {
        chat_mode: chatMode,
        chat_meta: chatMeta,
        messages: []
      };

      // 根据选中的record_id找到对应的完整消息
      selectedMessages.forEach(recordId => {
        const message = messageList.find(msg => msg.record_id === recordId);
        if (message) {
          params.messages.push(message);
        }
      });

      // 调用后端接口
      const response = await axiosInstance.post('/generate_theater', params);
      
      if (response.data.message === 'success') {
        showToast('生成成功,请到我的界面查看');
        setShowButtons(false);
        setShowTheaterPanel(false);
        setSelectedMessages([]);
      } else {
        throw new Error('Failed to generate theater');
      }
    } catch (error) {
      console.error('Failed to generate theater:', error);
      showToast('生成失败，请重试');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleCancel = () => {
    setShowButtons(false);
    setShowTheaterPanel(false);
    setSelectedMessages([]);
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
    setShowMenu(false); // 收起下部分扩展
    setShowTips(false);
  };
  const handleInputBlur = () => {
    setTimeout(() => {
      if (isFocus) {
        messageInput.current?.focus();
        return;
      }
      setIsInputFocused(false)
    })
  }

  const handleSend = async () => {
    if (!inputMessage.trim()) return;

    const newMessage = {
      id: Date.now(),
      role: 'user',
      text: inputMessage,
      chat_mode: chatMode
    };

    setMessageList(prev => [...prev, newMessage]);
    setInputMessage('');
    // 发送消息后自动滚动到底部
    requestAnimationFrame(() => {
      scrollToBottom();
    });
  };

  // 获取用户信息
  const fetchUserInfo = async () => {
    try {
      const response = await axiosInstance.get('/get_user');
      if (response.data?.result) {
        const userInfo = response.data.result;
        setUserSettings({
          user_nickname: userInfo.user_nickname || '',
          user_whoami: userInfo.user_whoami || '',
          user_isfemale: userInfo.user_isfemale || 0
        });
      }
    } catch (error) {
      console.error('Failed to fetch user info:', error);
    }
  };

  // 监听sidebar显示状态，显示时获取用户信息
  useEffect(() => {
    if (showSidebar) {
      fetchUserInfo();
    }
  }, [showSidebar]);

  const handleAudioClick = async (message) => {
    if (!message || !message.record_id) {
      console.error('Invalid message object:', message);
      return false;
    }

    console.log('Attempting to play audio for message:', message);

    try {
      if (!message.audio_url) {
        console.log('No audio_url, fetching from server...');
        const response = await axiosInstance.get('/get_chat_audio', {
          params: {
            ava_id: chatMeta.ava_id,
            chat_mode: message.chat_mode,
            record_id: message.record_id
          }
        });
        
        console.log('Server response:', response.data);
        
        if (response.data.result) {
          const audioUrl = response.data.result;
          console.log('Received audio URL:', audioUrl);
          
          // 更新消息列表中的audio_url
          setMessageList(prevMessages => 
            prevMessages.map(msg => 
              msg.record_id === message.record_id 
                ? { ...msg, audio_url: audioUrl }
                : msg
            )
          );
          scrollToBottom();
          // 播放新获取的音频
          if (audioUrl) {
            console.log('Playing new audio:', audioUrl);
            await playAudio(message.record_id, audioUrl);
            return true;
          } else {
            console.error('Received empty audio URL');
            return false;
          }
        } else {
          console.error('No result in server response');
          return false;
        }
      } else {
        // 直接播放已有的音频
        console.log('Playing existing audio:', message.audio_url);
        await playAudio(message.record_id, message.audio_url);
        return true;
      }
    } catch (error) {
      console.error('Error playing audio:', error);
      return false;
    }
  };

  // 处理音频队列的自动播放
  useEffect(() => {
    if (!isAudioAutoPlay || pendingAvaMessages.length === 0 || playingMessageId) {
      return;
    }

    const playNextMessage = async () => {
      const message = pendingAvaMessages[0];
      if (!message || !message.isNewMessage) {
        // 如果不是新消息，直接从队列中移除
        setPendingAvaMessages(prev => prev.slice(1));
        return;
      }

      console.log('Auto-playing audio for message:', message);
      await handleAudioClick(message);
      
      // 移除已播放的消息
      setPendingAvaMessages(prev => prev.slice(1));
    };

    playNextMessage();
  }, [pendingAvaMessages, playingMessageId, isAudioAutoPlay]);

  const renderMessageText = (text) => {
    if (!text) return '';
    
    // 使用更明确的Unicode码点来匹配中文括号
    // 中文括号的Unicode: （是\u（FF08, ）是\uFF09
    const parts = text.split(/(\u{FF08}.*?\u{FF09})/gu);
    
    return parts.map((part, index) => {
      // 更精确地检查是否是括号内的文本
      if (/^[\uFF08].*[\uFF09]$/.test(part)) {
        // 用className替代内联样式，避免生产环境优化问题
        return <span key={index} className="bracketed-text">{part}</span>;
      }
      return <span key={index}>{part}</span>;
    });
  };

  useEffect(() => {
    if (backgroundVideo) {
      // Preload the video
      const videoPreloader = document.createElement('video');
      videoPreloader.src = backgroundVideo;
      videoPreloader.style.display = 'none';
      videoPreloader.preload = 'auto';
      videoPreloader.muted = true;
      videoPreloader.load();
      
      // Cleanup
      return () => {
        if (videoPreloader) {
          videoPreloader.src = '';
        }
      };
    }
  }, [backgroundVideo]);

  useEffect(() => {
    if (backgroundVideo && chatMeta?.ava_preview) {
      const img = new Image();
      img.src = chatMeta.ava_preview;
    }
  }, [backgroundVideo, chatMeta?.ava_preview]);

  useEffect(() => {
    if (backgroundVideo) {
      // 使用console来调试背景视频的状态
      console.log("Current background video state:", { 
        url: backgroundVideo, 
        renderReady: videoRenderReady, 
        preloaded: isBackgroundVideoPreloaded, 
        ended: bgVideoEnded 
      });
    }
  }, [backgroundVideo, videoRenderReady, isBackgroundVideoPreloaded, bgVideoEnded]);

  useEffect(() => {
    if (backgroundVideo) {
      // Preload the video
      const videoPreloader = document.createElement('video');
      videoPreloader.src = backgroundVideo;
      videoPreloader.style.display = 'none';
      videoPreloader.preload = 'auto';
      videoPreloader.muted = true;
      videoPreloader.load();
      
      // Cleanup
      return () => {
        if (videoPreloader) {
          videoPreloader.src = '';
        }
      };
    }
  }, [backgroundVideo]);

  return (
    <div className="chatDetail">
      <div className="header">
        <div className="left">
          <div className="back" onClick={handleBackClick}>
            <img src={backIcon} alt="返回" />
          </div>
        </div>
        <div className="title">{statusTitle || chatMeta?.ava_name}</div>
        <div className="right">
          <img src={subMenuIcon} alt="边栏" onClick={handleSidebarToggle} />
        </div>
      </div>

      {isSecret && <div className="dotStatus">无限制模式</div>}

      {chatMode === 'circum' ? (
        <ReactPlayer
          url={circum_meta?.bg_pic}
          playing={true} // 尝试自动播放
          controls={false}
          muted={true} // 静音以支持自动播放
          loop
          playsInline={true}
          config={{
            file: {
              attributes: {
                "webkit-playsinline": "true",
                "x5-playsinline": "true",
                "x5-video-player-type": "h5",
                "x5-video-player-fullscreen": "false",
                "x5-video-orientation": "portraint",
                "disablePictureInPicture": "",
                "controlsList": "nodownload nofullscreen noremoteplayback"
              }
            }
          }}
          type="video/mp4"
          width='100%'
          height='100%'
          stopOnUnmount
          style={{
            position: 'fixed',
            objectFit: 'cover',
            top: 0,
            left: 0,
            zIndex: 0
          }}
        >
        </ReactPlayer>
      ) : (
        <>
          {/* Always show the default background */}
          {chatMeta?.ava_bg_pic && (
            <ReactPlayer
              key={`bg-pic-${bgVideoEnded}`} // Force remount when video ends
              url={chatMeta.ava_bg_pic}
              playing={true} 
              controls={false}
              loop={true} // Always loop the background image
              playsInline={true}
              config={{
                file: {
                  attributes: {
                    "webkit-playsinline": "true",
                    "x5-playsinline": "true",
                    "x5-video-player-type": "h5",
                    "x5-video-player-fullscreen": "false",
                    "x5-video-orientation": "portraint",
                    "disablePictureInPicture": "",
                    controlsList: "nodownload nofullscreen noremoteplayback"
                  }
                }
              }}
              muted={true}
              poster={chatMeta.ava_preview}
              type="video/mp4"
              width='100%'
              height='100%'
              stopOnUnmount
              style={{
                position: 'fixed',
                objectFit: 'cover',
                top: 0,
                left: 0,
                zIndex: 0,
                opacity: backgroundVideo && videoRenderReady && !bgVideoEnded ? 0 : 1,
              }}
            />
          )}

          {/* Conditionally render the background video player */}
          {backgroundVideo && !bgVideoEnded && (
            <ReactPlayer
              url={backgroundVideo}
              playing={true}
              controls={false}
              loop={false} // Only play once
              playsInline={true}
              config={{
                file: {
                  attributes: {
                    "webkit-playsinline": "true",
                    "x5-playsinline": "true",
                    "x5-video-player-type": "h5",
                    "x5-video-player-fullscreen": "false",
                    "x5-video-orientation": "portraint",
                    "disablePictureInPicture": "",
                    controlsList: "nodownload nofullscreen noremoteplayback"
                  }
                }
              }}
              poster={chatMeta.ava_preview} 
              muted={true}
              type="video/mp4"
              width='100%'
              height='100%'
              stopOnUnmount
              style={{
                position: 'fixed',
                objectFit: 'cover',
                top: 0,
                left: 0,
                zIndex: 0,
                opacity: videoRenderReady ? 1 : 0,
              }}
              onReady={() => {
                console.log("Background video preloaded and ready, url:", backgroundVideo);
                
                // 简化逻辑，直接设置为可见
                setTimeout(() => {
                  console.log("Setting video as ready to render");
                  setVideoRenderReady(true);
                  setIsBackgroundVideoPreloaded(true);
                }, 500); // 给视频一点时间来加载第一帧
              }}
              onEnded={() => {
                console.log("Background video ended, reverting to default background");
                // Immediate transition back to background image
                setBgVideoEnded(true);
                setVideoRenderReady(false);
                setIsBackgroundVideoPreloaded(false);
                
                // Only clear the URL after a delay to prevent component issues
                setTimeout(() => {
                  setBackgroundVideo('');
                }, 100);
              }}
            />
          )}
        </>
      )}

      <div className="messageList" ref={scrollViewRef}>
        {messageList.map((message, index) => (
          message.type === 'separator' ? (
            <div key={message.id} className="messageSeparator">
              <img src={message.icon} alt="separator" />
            </div>
          ) : (
            <div key={index} className={`messageItem ${message.role === 'ava' ? 'left' : 'right'}`}>
              {showButtons && (
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={selectedMessages.includes(message.record_id)}
                    onChange={() => handleCheckboxChange(message.record_id)}
                  />
                  <span className="checkmark"></span>
                </label>
              )}
              <div className="messageContent">
                {renderMessageText(message.text)}
                {message.role === 'ava' && (
                  <div className={`audioWaves ${playingMessageId === message.record_id ? 'playing' : ''}`} 
                       onClick={() => handleAudioClick(message)}>
                    {playingMessageId !== message.record_id ? (
                      <img src={audioIcon} alt="audio" />
                    ) : (
                      <>
                        <div className="wave wave-1" />
                        <div className="wave wave-2" />
                        <div className="wave wave-3" />
                        <div className="wave wave-4" />
                        <div className="wave wave-5" />
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        ))}
      </div>

      <div className={`inputArea ${showMenu ? 'expanded' : ''} ${isInputFocused ? 'is-input-focus' : ''}`}>
        {showTips && tipsList.length && (
          <div className="tipsPanel" key="tips-panel">
            <div className="tipsTitle">帮您准备了{tipsList.length}条回复，点击发送</div>
            {tipsList.map((tip, index) => (
              <div key={index} className="tipItem" onTouchEnd={() => handleSendMessage(tip)}>
                <div className="tipText">{tip}</div>
                <img src={sendIcon} alt="发送" />
              </div>
            ))}
          </div>
        )}
        <div className={`tips ${isInputFocused ? 'is-focus' : ''}`}>
            <div className="action" onClick={handleActionClick}>行动</div>
            <div className="tipIcon" onClick={handleTipClick}>
              <img src={tipsIcon} alt="提示" />
            </div>
        </div>
        <div className="inputWrapper">
          <div className="addButton" onClick={handleAddClick}>
            <img
              src={onGroupIcon}
              alt="添加"
              className={showMenu ? 'rotated' : ''}
            />
          </div>
          <div className="inputBox">
            <input
              ref={messageInput}
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onKeyUp={handleKeyPress}
            />
          </div>
          <div className="sendButton" onClick={e => handleSendMessage()}>
            <img src={sendIcon} alt="发送" />
          </div>
        </div>
        <div className={`menuPanel ${showMenu? 'showMenu': ''}`} key="menuPanel">
            {menuButtons.map(button => (
              <div key={button.index} className="menuItem" onClick={button.onClick}>
                <img src={button.icon} alt={button.label} />
                <span>{button.label}</span>
              </div>
            ))}
        </div>

        {showButtons && (
          <div className="buttonPanel">
            {/* Add your button panel content here */}
          </div>
        )}
      </div>

      {/* 底部上拉面板 */}
      <div className={`theaterPanel ${showTheaterPanel ? 'show' : ''}`}>
        <div className="selectedCount">
          已选{selectedMessages.length}/20
        </div>
        <div className="buttonGroup">
          <button 
            className="cancelButton"
            onClick={handleCancel}
          >
            取消
          </button>
          <button 
            className="generateButton"
            disabled={selectedMessages.length === 0 || isGenerating}
            onClick={handleGenerateTheater}
          >
            生成动态剧场
          </button>
        </div>
      </div>

      {/* 右侧设置边栏 */}
      {showSidebar && (
        <div className="settingsSidebar">
          <div className="sidebarContent">
            <div className="sectionTitle">我的对话设定</div>
            <div className="formBox">
              <div className="formItem">
                <label>昵称</label>
                <input
                  type="text"
                  value={userSettings.user_nickname}
                  onChange={(e) => setUserSettings({...userSettings, user_nickname: e.target.value})}
                />
              </div>
              <div className="formItem">
                <label>我是谁</label>
                <input
                  type="text"
                  value={userSettings.user_whoami}
                  onChange={(e) => setUserSettings({...userSettings, user_whoami: e.target.value})}
                />
              </div>
              <div className="formItem">
                <label>性别</label>
                <div className="radioGroup">
                  <label>
                    <input
                      type="radio"
                      checked={userSettings.user_isfemale === 0}
                      onChange={() => setUserSettings({...userSettings, user_isfemale: 0})}
                    />
                    <span>男</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      checked={userSettings.user_isfemale === 1}
                      onChange={() => setUserSettings({...userSettings, user_isfemale: 1})}
                    />
                    <span>女</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="sectionTitle">声音设置</div>
            <div className="settingItem">
              <span>自动播放对话语音</span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={isAudioAutoPlay}
                  onChange={(e) => setIsAudioAutoPlay(e.target.checked)}
                />
                <span className="slider"></span>
              </label>
            </div>

            <button 
              className="saveButton" 
              onClick={handleSettingsSave}
              disabled={isSaving}
            >
              <span>{isSaving ? "保存中..." : "保存"}</span>
            </button>
            <button 
              className="detailButton"
              onClick={async () => {
                try {
                  const response = await axiosInstance.get(`/get_ava?ava_id=${chatMeta.ava_id}`);
                  if (response.data.result) {
                    localStorage.setItem('avaMeta', JSON.stringify(response.data.result));
                    navigate(`/avadetail?ava_id=${chatMeta.ava_id}`);
                  }
                } catch (error) {
                  console.error('Failed to get ava details:', error);
                }
              }}
            >
              <span>查看人设</span>
              <img src={nextIcon} alt="next" className="arrow-right" />
            </button>
          </div>
          <div className="sidebarOverlay" onClick={() => setShowSidebar(false)} />
        </div>
      )}
      {ToastComponent}
    </div>
  );
};

export default ChatRoom;
