/**
 * 根据url下载路径
 * @param url
 * @param name
 */
export function downloadFileByUrl(url: string, name?: string) {
	if (!name) {
		const _url = new URL(url);
		name = _url.pathname.substring(_url.pathname.lastIndexOf('/') + 1);
	}
	const a = document.createElement('a');
	document.body.appendChild(a);
	a.href = `${url}`;
	a.download = name;
	a.target = '_blank';
	a.click();
	a.remove();
}

/**
 * 休眠时间
 * @param length 
 * @returns 
 */
export function sleep(time: number = 0) {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(true);
		}, time)
	})
}

/**
 * 设置光标的位子
 * @param node 
 * @param caret 
 */
export function setCaret(node, caret) {
	if (node.setSelectionRange) {
		node.focus();
		node.setSelectionRange(caret, caret);
		setTimeout(function(){
			node.setSelectionRange(caret, caret);
		})
	} else if (node.createTextRange) {
		var range = node.createTextRange();
		range.collapse(true);
		range.moveEnd('character', caret);
		range.moveStart('character', caret);
		range.select();
	}
}