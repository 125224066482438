import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../context/AxiosContext';
import backIcon from '../img/back.svg';
import searchIcon from '../img/search.svg';
import './SearchAva.scss';

const SearchAva = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState('');
  const [chatList, setChatList] = useState([]);

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.get('/search_ava', {
        params: {
          search_text: keyword
        }
      });
      if (response.data.results) {
        setChatList(response.data.results);
      }
    } catch (error) {
      console.error('Failed to search ava:', error);
    }
  };

  const handleChatClick = async (item) => {
    try {
      const response = await axiosInstance.get('/add_ava', {
        params: { ava_id: item.ava_id },
      });
      localStorage.setItem('chat_meta', JSON.stringify({
        ...response.data.result,
      }));
      navigate("/chatroom", {
        state: {
          chat_mode: 'default'
        }
      });
    } catch (error) {
      console.error('Failed to add Ava:', error);
    }
  };

  return (
    <div className="searchAva">
      <div className="header">
        <div className="back" onClick={() => navigate(-1)}>
          <img src={backIcon} alt="返回" />
        </div>
        <div className="search-container">
          <form onSubmit={handleSearch}>
            <div className="search-box">
              <input
                type="text"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="请输入"
              />
              <button type="submit">
                <img src={searchIcon} alt="搜索" />
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="chat-list">
        {chatList.map((item, index) => (
          <div key={index} className="item" onClick={() => handleChatClick(item)}>
            <div className="item-content">
              <div className="photo">
                <img src={item.ava_avatar} alt={item.ava_name} />
              </div>
              <div className="msg-box">
                <div className="name">
                  <div className="name-text">{item.ava_name}</div>
                  <div className="status">
                    <span className={item.is_friend ? 'connected' : ''}>
                      {item.is_friend ? '已连接' : '未连接'}
                    </span>
                  </div>
                </div>
                <div className="msg">已进行{item.friend_num}次连接</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchAva;
